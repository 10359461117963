import React, { ChangeEvent, useCallback, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApolloClient, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsRequiredPlaceholdersModalOpen,
  selectIsSendViaEmailModalOpen,
} from '../../../../../../../store/modals/modals.selectors';

import ReviewViaEmailModal from './ReviewViaEmailModal';

import PlaceholderWarningModal from '../../../../../lease-document-wizard-modal/custom-modals/placeholderWarningModal/placeholderWarningModal';


type ReviewViaEmailModalContainerProps = {
  isOpen: boolean;
  onClose: () => void;
  teamId: string;
  dealId: string;
  placeholdersToComplete?: number;
};

const ReviewViaEmailModalContainer = ({
  isOpen,
  onClose,
  teamId,
  dealId,
  placeholdersToComplete = 0,
}: ReviewViaEmailModalContainerProps) => {

  const dispatch = useDispatch();

  const hasRequiredPlaceholders = placeholdersToComplete !== 0;
  
  const isRequiredPlaceholdersModalOpen = useSelector(selectIsRequiredPlaceholdersModalOpen);

  const isReviewViaEmailModalOpen = useSelector(selectIsSendViaEmailModalOpen);

  const [showWarningModal, setShowWarningModal] = useState(isOpen && hasRequiredPlaceholders);

  const [showModal, setShowModal] = useState(isOpen && !hasRequiredPlaceholders);

  const handleWarningModalClose = () => {
    setShowWarningModal(false);
    setShowModal(false);
    onClose();
  }

  useEffect(() => {
    setShowWarningModal(isOpen && hasRequiredPlaceholders);

    setShowModal(isOpen && !hasRequiredPlaceholders);
  }, [isOpen]);

  return (
    <>
      <ReviewViaEmailModal
        isOpen={showModal}
        onClose={onClose}
        teamId={teamId}
        dealId={dealId}
      ></ReviewViaEmailModal>      
      <PlaceholderWarningModal
        isOpen={showWarningModal}
        placeholders={placeholdersToComplete}
        onContinue={() => {
          setShowWarningModal(false);
          setShowModal(true);
        }}
        onReview={handleWarningModalClose}
        onHide={handleWarningModalClose}
      />
    </>
  );
};

export default ReviewViaEmailModalContainer;