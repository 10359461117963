// values are for visual representation of the format.
// the keys are checked for existence, values are not checked

export const SupportedFileTypes = {
  'application/pdf': 'pdf',
  // 'image/svg+xml': 'svg',
  // 'image/bmp': 'bmp',
  // 'image/jpeg': 'jpg',
  // 'image/png': 'png',
};

export const SupportedTemplateFileTypes = {
  'application/pdf': 'pdf',
};

export const SupportedDocFileTypes = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
};

export const INCORRECT_FILE_FORMAT_ERROR = `Incorrect file extension. Supported extensions: {extensions}`;
