import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useNotifications } from '../notifications';
import { InviteReceivingTeamFormikProps } from '../consts/forms-types';
import { CreateTeamErrorMessage } from '../consts/forms-constants';
import AddNewTeamForm from './AddNewTeamForm';
import { mutations, queries, usePartyIds } from '../api';
import { useTotango } from '../shared/hooks/useTotango';
import { TOTANGO_EVENT_TYPES } from '../utils/totango';

const schema = yup.object().shape({
  teamName: yup.string().required(),
});

const AddNewTeamFormik = ({
  bodyContainer,
  actionsContainer,
  renderSecondaryAction,
  onSuccess,
}: InviteReceivingTeamFormikProps) => {
  const { partyIds } = usePartyIds();
  const navigate = useNavigate();
  const [, { error: notifyError }] = useNotifications();
  const [createTeam] = useMutation(mutations.createTeam);
  const sendTotango = useTotango();

  const navigateToNewTeamPage = (teamId: string) => {
    const dealUrl = `/teams/${teamId}/deals`;
    return navigate(dealUrl);
  };

  const onSubmit = async (values: any) => {
    createTeam({
      variables: {
        name: values.teamName,
        partyId: partyIds.landlord,
      },
      refetchQueries: [
        {
          query: queries.getCurrentUser,
        },
      ],
    })
      .then(({ data }: any) => {
        const { id: teamId } = data.createTeam;
        onSuccess?.();
        navigateToNewTeamPage(teamId);
      })
      .catch(() => notifyError({ id: 'deal.send-deal.error', message: CreateTeamErrorMessage.CREATE_TEAM }));
  };

  return (
    <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={{ teamName: '' }}>
      {formikProps => (
        <AddNewTeamForm
          {...formikProps}
          bodyContainer={bodyContainer}
          actionsContainer={actionsContainer}
          renderSecondaryAction={renderSecondaryAction}
        />
      )}
    </Formik>
  );
};

export default AddNewTeamFormik;
