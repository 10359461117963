import React, { useCallback, useMemo } from 'react';
import TeamMembersTable from './TeamMembersTable';
import { useExpanded, usePagination, useSortBy, useTable } from 'react-table';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from 'react-bootstrap/Button';
import { ReactComponent as ChevronUpIcon } from '../../../../shared/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../../../shared/icons/chevron-down.svg';
import Avatar from '../../../avatar';
import { mutations, queriesV2 } from '../../../../api';
import { useNotifications } from '../../../../notifications';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { getTeamById } from '../../../../api/queries';
import TeamMemberRow from './components/TeamMemberRow';
import { useDealId } from '../../../../deal-id-context';
import { useTeamInfo } from '../../../../team-id-context';
import { ApolloError } from '@apollo/client';
import InlinePermissionsList from '../../../inline-permissions-list';
import { useAuth } from '../../../../auth';
import { filterTeamMembersToDisplay, ParsedTeamMember, parseInvitation, parseTeamMember } from './helpers';
import { Permissions } from '../../../../shared/constants/permissions';
import LoadingSpinner from '../../../loading-spinner';
import { useLeaseDocumentTeamPermissions } from '../../../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { TOTANGO_EVENT_TYPES } from '../../../../utils/totango';
import { useTotango } from '../../../../shared/hooks/useTotango';
import TeamMembersList from '../teamMembersList';

interface Props {
  isReadOnly?: boolean;
  showDealMembersOnly?: boolean;
  setUserToRemoveFromDeal: (user: any) => void;
  setUserToRemove: (user: any) => void;
  dealsData: any;
  dealsGraphqlError?: ApolloError;
  teamData: any;
  dealData: any;
  sorting?: string;
  dealMembersIds: string[];
  shouldShowPendingInvitations?: boolean;
}

const TeamMembersTableContainer = ({
  isReadOnly = false,
  showDealMembersOnly = false,
  setUserToRemove,
  setUserToRemoveFromDeal,
  dealsData,
  dealsGraphqlError,
  teamData,
  dealData,
  sorting,
  dealMembersIds,
  shouldShowPendingInvitations = true,
}: Props) => {
  const apolloClient = useApolloClient();
  const [, { error: notifyError, success: notifySuccess }] = useNotifications();
  const dealId = useDealId();
  const { teamId } = useTeamInfo();
  const { formatMessage } = useIntl();
  // @ts-ignore
  const [{ user }] = useAuth();
  const currentUserIsAdmin = teamData?.teamById?.adminUser?.id === user.id;
  const permissions = useLeaseDocumentTeamPermissions({ apolloClient, teamId });
  const hasUserInvitePermission = !!permissions?.hasInviteSendingPermission;

  const deal = dealData?.getDealById ?? dealsData?.dealsForTeam.find((deal: any) => deal.id === dealId);

  const { data: allPermissions, loading: isAllPermissionsLoading } = useQuery(queriesV2.getPermissions);
  const whitelistPermissionId = allPermissions?.permissions.find(
    ({ key }: { key: string }) => key === Permissions.Whitelist
  )?.id;
  const [resendInvitation] = useMutation(mutations.resendInvitation);
  const [cancelInvitation] = useMutation(mutations.cancelInvitation, {
    refetchQueries: [
      {
        query: getTeamById,
        variables: { teamId },
      },
    ],
  });
  const sendTotango = useTotango();

  const handleResendInvitation = useCallback(
    async (invitationId, email) => {
      try {
        await resendInvitation({ variables: { invitationId } });
        sendTotango(TOTANGO_EVENT_TYPES.teamMemberInvited);
        notifySuccess({
          message: {
            id: 'phrase.resend-invitation.success',
            defaultMessage: 'A new invitation has been sent to {email}',
            values: {
              email,
            },
          },
        });
      } catch (error) {
        notifyError({ message: error });
      }
    },
    [notifyError, notifySuccess, resendInvitation, sendTotango]
  );

  const handleCancelInvitation = useCallback(
    async (invitationId, email) => {
      try {
        await cancelInvitation({ variables: { invitationId } });
        notifySuccess({
          message: {
            id: 'phrase.cancel-invitation.success',
            defaultMessage: 'Invitation has been cancelled',
            values: {
              email,
            },
          },
        });
      } catch (error) {
        notifyError({ message: error });
      }
    },
    [cancelInvitation, notifyError, notifySuccess]
  );

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage id="title.team.fullName" defaultMessage="Name" />,
        accessor: 'fullName',
        Cell: ({
          row: {
            original: { id, email },
          },
          cell: { value: fullName },
        }: any) => (
          <div className="d-flex align-items-center">
            <Avatar email={email} fullName={fullName} className="mr-2" />
            <span
              className={`${showDealMembersOnly && 'text-truncate'}`}
              style={{ width: `${showDealMembersOnly && '200px'}` }}
            >
              {fullName}
              {id === user.id && (
                <>
                  {' '}
                  <FormattedMessage id="phrase.hint.this-is-you" defaultMessage="(you)" />
                </>
              )}
            </span>
          </div>
        ),
      },
      {
        Header: <FormattedMessage id="title.team.email" defaultMessage="Email" />,
        accessor: 'email',
        Cell: ({ cell: { value: email } }: any) => {
          return (
            <div
              className={`${showDealMembersOnly && 'text-truncate'}`}
              style={{ width: `${showDealMembersOnly && '200px'}` }}
            >
              <FormattedMessage id="permission.user-level.user" defaultMessage={email} />
            </div>
          );
        },
      },
      {
        Header:
          currentUserIsAdmin || hasUserInvitePermission ? (
            <FormattedMessage id="title.team.permissions" defaultMessage="Permissions" />
          ) : (
            <FormattedMessage id="title.team.role" defaultMessage="Role" />
          ),
        accessor: 'permissions',
        Cell: ({ row, cell: { value: permissions } }: any) => {
          return currentUserIsAdmin || hasUserInvitePermission ? (
            <div className="d-flex align-items-center justify-content-between" style={{ minWidth: '200px' }}>
              <InlinePermissionsList permissions={permissions} />
              <Button
                variant="link"
                {...row.getToggleRowExpandedProps()}
                title={formatMessage({
                  id: 'cta.more-options.toggle',
                  defaultMessage: 'Toggle more options',
                })}
                className="ml-2"
                onClick={() => {}}
              >
                {row.isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Button>
            </div>
          ) : (
            <span>
              {row.original.isAdmin ? (
                <FormattedMessage id="permission.user-level.admin" defaultMessage="Admin" />
              ) : (
                <FormattedMessage id="permission.user-level.user" defaultMessage="User" />
              )}
            </span>
          );
        },
      },
    ],
    [
      currentUserIsAdmin,
      user.id,
      formatMessage,
      showDealMembersOnly,
      setUserToRemoveFromDeal,
      handleResendInvitation,
      handleCancelInvitation,
      hasUserInvitePermission,
    ]
  );

  const data = useMemo(() => {
    if (!teamData) return [];

    const teamMembersToDisplay: ParsedTeamMember[] = teamData.teamById.teamMembers
      .filter(({ user }: any) => {
        return showDealMembersOnly ? dealMembersIds.includes(user.id) : true;
      })
      .map(({ teamPermissions: teamMemberPermissions, user: member }: any) => {
        const isAdmin = member.id === teamData.teamById.adminUser.id;
        const hasAccessToDeal = deal?.dealAccessUsers.includes(member.id);

        const memberPermissions = isAdmin
          ? allPermissions?.permissions.map((permission: any) => ({ allowed: true, permission }))
          : teamMemberPermissions;

        return parseTeamMember({ memberPermissions: memberPermissions || [], member, isAdmin, hasAccessToDeal });
      });

    const pendingInvitations: ParsedTeamMember[] =
      ((showDealMembersOnly && hasUserInvitePermission) || !showDealMembersOnly) && shouldShowPendingInvitations
        ? teamData.teamById.pendingInvitations
            .map((invitation: { id: string; permissions: any[]; email: string; dealIds: string[] }) => {
              const hasAccessToDeal = !deal ? false : invitation.dealIds.includes(deal.id);
              return parseInvitation({ invitation, hasAccessToDeal });
            })
            .filter((invitation: any) => {
              if (deal) return invitation.hasAccessToDeal;
              return true;
            })
        : [];

    const members = [...teamMembersToDisplay, ...pendingInvitations]
      .filter(teamMember => filterTeamMembersToDisplay({ teamMember, deal, currentDealId: dealId }))
      .map(el => {
        return {
          ...el,
          onRowClick: (row: any) => {
            row.toggleRowExpanded();
          },
        };
      });

    members.sort((a, b) => {
      switch (sorting) {
        case 'ascName':
          return a.fullName.localeCompare(b.fullName);

        case 'descName':
          return b.fullName.localeCompare(a.fullName);

        case 'ascEmail':
          return a.email.localeCompare(b.email);

        case 'descEmail':
          return b.email.localeCompare(a.email);

        default:
          return 0;
      }
    });

    return members;
  }, [
    teamData,
    allPermissions,
    showDealMembersOnly,
    dealMembersIds,
    deal,
    dealId,
    shouldShowPendingInvitations,
    hasUserInvitePermission,
    sorting,
  ]);

  const table = useTable(
    {
      data,
      // @ts-ignore
      columns,
      autoResetExpanded: false,
      autoResetPage: false,
      getRowId: useCallback(
        (row, relativeIndex, parent) => [...(parent ? [parent.id] : []), row.id ?? relativeIndex].join('.'),
        []
      ),
    },
    useSortBy,
    useExpanded,
    !showDealMembersOnly && usePagination
  );

  const renderRowSubComponent = useCallback(
    row => {
      const { original: userRowData } = row;
      const isRowUserCurrent = user.id === userRowData.id;
      const shouldShowRemoveTeamMemberFromTeamButton =
        !userRowData.isAdmin && !userRowData.invitationId && !showDealMembersOnly && !isRowUserCurrent;
      const shouldShowRemoveTeamMemberFromDealButton =
        !userRowData.isAdmin && !userRowData.invitationId && showDealMembersOnly && !isRowUserCurrent;
      const shouldShowDealsSelector =
        ((!isReadOnly && currentUserIsAdmin) ||
          (!isReadOnly &&
            hasUserInvitePermission &&
            !currentUserIsAdmin &&
            !userRowData.isAdmin &&
            !isRowUserCurrent)) &&
        !dealId;

      return (
        <TeamMemberRow
          handleResendInvitation={handleResendInvitation}
          handleCancelInvitation={handleCancelInvitation}
          shouldShowRemoveTeamMemberFromTeamButton={shouldShowRemoveTeamMemberFromTeamButton}
          shouldShowRemoveTeamMemberFromDealButton={shouldShowRemoveTeamMemberFromDealButton}
          shouldShowDealsSelector={shouldShowDealsSelector}
          userRow={row}
          isTableReadOnly={isReadOnly}
          setUserToRemove={setUserToRemove}
          setUserToRemoveFromDeal={setUserToRemoveFromDeal}
          dealsData={dealsData}
          dealsGraphqlError={dealsGraphqlError}
          whitelistPermissionId={whitelistPermissionId!}
        />
      );
    },
    [
      showDealMembersOnly,
      isReadOnly,
      handleResendInvitation,
      handleCancelInvitation,
      setUserToRemove,
      setUserToRemoveFromDeal,
      dealsData,
      dealsGraphqlError,
      whitelistPermissionId,
      currentUserIsAdmin,
      hasUserInvitePermission,
      user,
      dealId,
    ]
  );

  if (isAllPermissionsLoading || !teamData) return <LoadingSpinner />;

  return (
    <>
      <div className="non-mobile">
        <TeamMembersTable
          table={table}
          renderRowSubComponent={renderRowSubComponent}
          shouldPaginationBeRendered={!showDealMembersOnly}
        />
      </div>
      <div className="mobile">
        <TeamMembersList
          members={data}
          showDealMembersOnly={showDealMembersOnly}
          isReadOnly={isReadOnly}
          dealsData={dealsData}
          whitelistPermissionId={whitelistPermissionId}
          shouldShowDealsSelector={
            ((!isReadOnly && currentUserIsAdmin) || (!isReadOnly && hasUserInvitePermission && !currentUserIsAdmin)) &&
            !dealId
          }
        />
      </div>
    </>
  );
};

export default TeamMembersTableContainer;
