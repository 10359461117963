import { gql } from '@apollo/client';
import { graphql } from '../gql';

export const getDocumentById =  graphql(/* GraphQL */`
  query GetDocumentById($documentId: ID!) {
    getDocumentById(id: $documentId) {
      id
      status
      interactionsFeedToken
      shouldReturnForReceiverReview
      currentUserRole
      title
      currentTeamPossession
      eversignStatus
      documentSuggestions {
        id
        externalId
        status
      }
      deal {
        id
        createDate
        dealType
        dealSizeInSquareFeet
        createDate
        receiverEmail
        receiverInvitedDate
        receiverAcceptedInvitationDate
      }
      team {
        id
        adminUser {
          id
          email
          fullName
        }
      }
      receiverTeam {
        id
        adminUser {
          id
          email
          fullName
        }
      }
      receiverTeamAssignedAt
      eversignPdfUrl
      sharedBy
      signers {
        id
        email
        signedAt
        embeddedSigningUrl
        order
      }
      importedAssetUrl
      originalImportedAssetUrl
      source
      importedAssetDownloadPath
      importedAssetContentType
      approvalRequest {
        id
        status
        threshold
        creator {
          id
        }
        reviewers {
          id
        }
        reviews {
          id
          approvedAt
          rejectedAt
          reviewer {
            id
            fullName
            email
          }
        }
        totalReviews
        totalApproved
      }
      interactionsFeedToken
      isEditable
      isTitleEditable
      editorConfiguration
    }
  }
`);


export const getDocumentsByDeal = graphql(/* GraphQL */`
  query GetDocumentsByDeal($dealId: ID!) {
    getDocumentsByDeal(dealId: $dealId) {
      id
      receiverTeam {
        id
        adminUser {
          id
        }
      }
      interactionsFeedToken
      approvalRequest {
        id
        status
        threshold
        creator {
          id
        }
        reviewers {
          id
        }
        reviews {
          id
          approvedAt
          rejectedAt
          reviewer {
            id
            fullName
            email
          }
        }
        totalReviews
        totalApproved
      }
      possessionTeam {
        id
      }
      documentSuggestions {
        id
        externalId
        status
      }
      possessionTeam {
        id
      }
      isEditable
      isTitleEditable
      currentTeamPossession
      lastUpdated
      title
      status
      currentUserRole
      eversignPdfUrl
      sharedBy
      receiverTeamAssignedAt
      shouldReturnForReceiverReview
      signers {
        id
        email
        signedAt
        embeddedSigningUrl
        order
      }
      editorConfiguration
      importedAssetUrl
      originalImportedAssetUrl
      source
      importedAssetDownloadPath
      importedAssetContentType
      generatedDocumentPdfUrl
      importedAssetContentType
      isEligibleForSigningPrep
    }
  }
`);

export const getDocumentTemplates =  graphql(/* GraphQL */`
  query getDocumentTemplates($teamId: ID, $templateType: String) {
    getDocumentTemplates(teamId: $teamId, templateType: $templateType) {
      id
      country
      state
      name
      description
      dealType
      status
      hidden
      customTemplate
      template
      templateType
      name
      team {
        id
        uuid
        name
      }
      editorConfiguration
      source
      updatedAt
    }
  }
`);

export const getOrganizationsTemplatesByUser = gql`
  query organizationsTemplatesByUser($teamId: ID, $templateType: String) {
    organizationsTemplatesByUser(teamId: $teamId, templateType: $templateType) {
      id
      country
      state
      name
      description
      dealType
      status
      hidden
      updatedAt
      customTemplate
      template
      templateType
      editorConfiguration
      inputXfdfUrl
      importedAssetContentType
      importedAssetUrl
      source
      name
      team {
        name
        id
        uuid
        organization {
          id
          name
        }
      }
    }
  }
`;

export const getDocumentTemplateById = gql`
  query getDocumentTemplateById($documentTemplateId: ID!) {
    getDocumentTemplateById(documentTemplateId: $documentTemplateId) {
      id
      country
      state
      name
      description
      dealType
      customTemplate
      updatedAt
      template
      source
      importedAssetUrl
      originalImportedAssetUrl
      importedAssetContentType
      team {
        id
      }
      editorConfiguration
      templateType
    }
  }
`;

// Always specific to the current user, and returns a string (the JWT token)
export const getCkeditorDocumentToken = graphql(/* GraphQL */`
  query GetDocumentCkeditorToken($documentId: ID!) {
    getCkeditorDocumentToken(documentId: $documentId)
  }
`);

export const getCkeditorTemplateToken = graphql(/* GraphQL */`
  query getCkeditorTemplateToken($documentTemplateId: ID!) {
    getCkeditorTemplateToken(documentTemplateId: $documentTemplateId)
  }
`);

export const getDealTeamsByTeamId = gql`
  query GetDealTeamsByDealId($dealId: ID!) {
    getDealById(dealId: $dealId) {
      id
      receiverAcceptedInvitationDate
      receiverEmail
      dealAccessUsers
      receiverTeam {
        id
        adminUser {
          id
          email
          fullName
        }
        approvers(dealId: $dealId) {
          id
          fullName
          companyName
          email
        }
        teamMembers {
          user {
            id
            email
            fullName
          }
          teamPermissions {
            key
            allowed
          }
        }
      }
      team {
        id
        approvers(dealId: $dealId) {
          id
          fullName
          companyName
          email
        }
        teamMembers {
          user {
            id
            email
            fullName
          }
          teamPermissions {
            key
            allowed
          }
        }
      }
      members {
        id
        email
        fullName
      }
      timelineMembers {
        id
        email
        fullName
      }
    }
  }
`;

export const getDealCreatorTeamsByDealId = gql`
  query GetDealCreatorTeamsByDealId($dealId: ID!) {
    getDealById(dealId: $dealId) {
      id
      team {
        teamMembers {
          user {
            id
            email
            fullName
          }
          teamPermissions {
            key
            allowed
          }
        }
      }
      members {
        id
      }
    }
  }
`;

export const getSigningUrlByToken = graphql(/* GraphQL */`
  query getSigningUrlByToken($token: String!) {
    getSigningUrlForToken(token: $token)
  }
`);

export const getCurrentUserTeamsWhereUserHasEditPermissions =  gql`
  query GetCurrentUserTeamsWhereUserHasEditPermissions {
    currentUser {
      id
      teamPermissions {
        key
        allowed
        teamId
        teamName
      }
      ownedTeams {
        id
        name
      }
    }
  }
`;

export const getPermissions = graphql(/* GraphQL */`
  query GetPermissions {
    permissions {
      id
      name
      key
      displayOrder
      description
    }
  }
`);

export const getDocumentSigners = graphql(/* GraphQL */`
  query GetDocumentSigners($documentId: ID!) {
    getDocumentById(id: $documentId) {
      id
      signers {
        id
        email
        order
        signedAt
      }
    }
  }
`);

export const getDocumentRecipients = graphql(/* GraphQL */`
  query GetDocumentRecipients($documentId: ID!) {
    getDocumentById(id: $documentId) {
      id
      ccRecipients
    }
  }
`);

export const getGeneratedDocumentPdf = graphql(/* GraphQL */`
  query GetDocumentPdfUrl($documentId: ID!) {
    getDocumentById(id: $documentId) {
      id
      generatedDocumentPdfUrl
    }
  }
`);

export const getDocumentFeedbackById = graphql(/* GraphQL */`
  query GetDocumentFeedback($id: ID!) {
    getDocumentFeedbackById(id: $id) {
      id
      document {
        id
      }
      subject
      sender
      htmlContent
      emailAttachments
      createdAt
    }
  }
`);

export const getAlertableDocumentsByTeam =  graphql(/* GraphQL */`
  query GetAlertableDocumentsByTeam($teamId: ID!, $dealId: ID) {
    getAlertableDocumentsByTeam(teamId: $teamId, dealId: $dealId) {
      id
    }
  }
`);

export const getDocumentPlaceholderCompleteness =  graphql(/* GraphQL */`
  query GetDocumentPlaceholderCompleteness($documentId: ID!, $documentType: String!) {
    getDocumentPlaceholderCompleteness(documentId: $documentId, documentType: $documentType) {
      placeholders {
        uuid
        id
        title
        isRequired
        status
      }
      success
    }
  }
`);
