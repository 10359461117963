import React, { ChangeEvent, Fragment, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApolloClient, useQuery } from '@apollo/client';
import { Row as RowTable, usePagination, useSortBy, useTable } from 'react-table';
import { Button, Modal } from 'react-bootstrap';
import dayjs from 'dayjs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getDocumentTemplates, getOrganizationsTemplatesByUser } from '../../api/queries_v2';
import { fillPlaceholders, paths, useNavigate } from '../../routing';
import slugify from '../../utils/slugify';
import { useTeamInfo } from '../../team-id-context';
import { usePermissions } from '../../auth';
import { CreatedTemplateTypes, useUserRestrictions } from '../../auth/user-restrictions';
import { Dropdown, DropdownBody, DropdownItem, DropdownToggle, DropdownToggleOrange } from '../../components/dropdown';
import SearchInput from '../../components/search-input';
import { ContentHeader } from '../../components/private-layout';
import { Table } from '../../components/table';
import { Checkbox } from '../../components/checkbox-or-radio-field';
import LoadingSpinner from '../../components/loading-spinner';
import Controls, { TemplateStatus } from './Controls';
import AddNewTemplateFormik from '../../forms/AddNewTemplateFormContainer';
import {
  useLeaseDocumentTeamPermissions,
} from '../lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { Permissions } from '../../shared/constants/permissions';
import { TemplateType } from '../../consts/forms-types';
import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveModal, SetActiveModalAction } from '../../store/modals/modals.actions';
import { ModalActionsEnum } from '../../components/document-table/DocumentTableConstants';
import { FileUploadModal } from '../../components/shared/modals/fileUploadModal';
import { selectActiveModal } from '../../store/modals/modals.selectors';
import SubscriptionRequiredModal from '../modals/subscriptionModal/SubscriptionRequiredModal';
import Tooltip from '../../components/tooltip';
import { ReactComponent as FilterIcon } from '../../shared/icons/filter-icon.svg';
import { fuzzyInputSearch } from '../deals/helpers/determineDealsActionModalMessage';
import { TitleFormattedMessage } from '../../components/titleFormattedMessage';
import { DocumentTemplateType, getTemplateType } from '../../shared/templateType';
import { ContentBody } from '../../components/private-layout/ContentBody';
import classNames from 'classnames';
import { MobileTable } from '../../components/mobile-layout/MobileTable';
import { MobileTemplateItem } from '../../components/mobile-layout/MobileTemplateItem';
import FilterSidebar from './components/filterSidebar';
import { statusOptions, templateStatusMessages, templateTypeMessages, templateTypeOptions } from './constants';

enum AddTemplateActionStatus {
  IMPORT_FROM_WORD = 'Import From Word',
  UPLOADED_PDF = 'Upload PDF',
  BLANK_TEMPLATE = 'Blank Template',
}

type CreateTemplateDropdownActionsType = {
  text: AddTemplateActionStatus;
  onClick: (() => SetActiveModalAction) | (() => void);
  isAvailable: boolean;
};

type ColumnsCellType = {
  cell: {
    value: string;
    row: {
      values: {
        template: {
          id: string;
          organizationName: string;
          fromOrganization?: boolean;
        };
        status?: string;
        templateType?: TemplateType;
      };
    };
  };
};

const defaultTypeOptions = new Set<string>([
  DocumentTemplateType.PDF,
  DocumentTemplateType.FULLY_EDITABLE,
  DocumentTemplateType.RESTRICTED,
]);
const defaultTemplateStatusOptions = new Set<string>([TemplateStatus.ACTIVE]);

const TemplatesLandingPage = () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { teamId } = useTeamInfo();
  const navigate = useNavigate();
  const { hasAccessToTemplates, creatableTemplateTypes  } = useUserRestrictions();
  const activeModal = useSelector(selectActiveModal);
  const { isAdmin, hasPermission } = usePermissions() as {
    isAdmin: boolean;
    hasPermission: (permission: string) => boolean;
  };
  const apolloClient = useApolloClient();
  const [showAddTemplate, setShowAddTemplate] = useState(false);
  const [showEditTemplate, setShowEditTemplate] = useState(false);
  const [selectedTemplateType, setSelectedTemplateType] = useState<TemplateType | null>(null);
  const [filterText, setFilterText] = useState('');
  const [filterCreators, setFilterCreators] = useState(new Set());
  const [filterStatuses, setFilterStatuses] = React.useState(new Set(defaultTemplateStatusOptions));
  const [filterTemplateTypes, setFilterTemplateTypes] = React.useState(new Set(defaultTypeOptions));
  const [showFilter, setShowFilter] = useState(false);
  const [sorting, setSorting] = useState('');
  const [isSubscriptionModalActive, setSubscriptionModalActive] = useState(false);
  const canEdit = hasPermission(Permissions.Edit);
  const isTemplateEditable = isAdmin || canEdit;

  const { loading: isDocumentTemplatesDataLoading, data: documentTemplatesData, refetch } = useQuery(
    getDocumentTemplates,
    {
      fetchPolicy: 'network-only',
      skip: !teamId,
      variables: {
        teamId,
        templateType: 'agreement',
      },
    }
  );

  const { loading: isUserOrgsTemplatesDataLoading, data: userOrgsTemplatesData } = useQuery(
    getOrganizationsTemplatesByUser,
    {
      fetchPolicy: 'network-only',
      skip: !teamId,
      variables: {
        teamId,
        templateType: 'agreement',
      },
    }
  );

  const allTemplates = useMemo(() => {
    if (isDocumentTemplatesDataLoading || isUserOrgsTemplatesDataLoading || !documentTemplatesData) {
      return [];
    }

    const userTemplatesIds = documentTemplatesData?.getDocumentTemplates.map((template: any) => template.id);
    const organizationTemplates =
      userOrgsTemplatesData?.organizationsTemplatesByUser
        .filter((orgTemplate: any) => !userTemplatesIds?.includes(orgTemplate.id))
        .map((orgTemplate: any) => {
          return { ...orgTemplate, fromOrganization: true };
        }) ?? [];

    return [...(documentTemplatesData?.getDocumentTemplates ?? []), ...organizationTemplates];
  }, [isDocumentTemplatesDataLoading, documentTemplatesData, isUserOrgsTemplatesDataLoading, userOrgsTemplatesData]);

  const allCreators = useMemo(() => {
    if (!allTemplates) {
      return [];
    }

    const sortedCreatorsData = allTemplates
      .map((template: any) => (template?.team?.id !== teamId ? template?.team?.name : ''))
      .filter((value: any, index: any, self: any) => self.indexOf(value) === index && !!value)
      .sort((a: any, b: any) => a.localeCompare(b));

    const userTeam = allTemplates.find((template: any) => template?.team?.id === teamId);

    if (userTeam) {
      sortedCreatorsData.unshift(`${userTeam.team?.name} (Your Team)`);
    }

    return sortedCreatorsData;
  }, [allTemplates, teamId]);

  useEffect(() => {
    setFilterCreators(new Set(allCreators));
  }, [allCreators]);

  const templateIsUpdated = useCallback(() => {
    setShowAddTemplate(false);
    refetch();
  }, [refetch]);

  const onAddTemplateModalHide = () => {
    setShowAddTemplate(false);
    setSelectedTemplateType(null);
  };

  const onAddTemplateShow = (templateType: TemplateType) => {
    setShowAddTemplate(true);
    setSelectedTemplateType(templateType);
  };

  const handleDocFileUploadModal = useCallback(() => {
    return dispatch(setActiveModal(ModalActionsEnum.UPLOAD_DOC_FILE));
  }, [dispatch]);

  const handleFileUploadModal = useCallback(() => {
    return dispatch(setActiveModal(ModalActionsEnum.UPLOAD_FILE));
  }, [dispatch]);

  const permissions = useLeaseDocumentTeamPermissions({ apolloClient, teamId });

  const columns = useMemo(
    () => [
      {
        Header: <FormattedMessage id="title.deals.template-title" defaultMessage="Template Name" />,
        accessor: 'name',
        Cell: ({ cell: { value } }: ColumnsCellType) => {
          return (
            <div className={styles.headerTitleTableColumn}>
              <FormattedMessage id="cta.go-to.details" defaultMessage={value} />
            </div>
          );
        },
      },
      {
        Header: <FormattedMessage id="title.deals.template-creator" defaultMessage="Creator" />,
        accessor: 'templateCreator',
      },
      {
        Header: <FormattedMessage id="title.deals.template-type" defaultMessage="Type" />,
        accessor: 'templateType',
      },
      {
        Header: <FormattedMessage id="title.deals.template-status" defaultMessage="Status" />,
        accessor: 'status',
        Cell: ({
          cell: {
            value,
            row: {
              values: { status },
            },
          },
        }: ColumnsCellType) => {
          return (
            <span className={styles.templateStatus}>
              <FormattedMessage
                id="phrase.template-status-readout"
                defaultMessage={status}
                values={{ status, permissions }}
              />
            </span>
          );
        },
      },
      {
        Header: <FormattedMessage id="title.templates.last-updated" defaultMessage="Last Updated" />,
        accessor: 'updatedAt',
        sortType: (a: RowTable<{ values: { updatedAt: string } }>, b: RowTable<{ values: { updatedAt: string } }>) => {
          return new Date(b.values.updatedAt).getTime() - new Date(a.values.updatedAt).getTime();
        },
        Cell: ({ cell: { value } }: any) => {
          return <span className="text-nowrap">{value && dayjs(value).format('LL')}</span>;
        },
      },
      {
        Header: (
          <span className="sr-only">
            <FormattedMessage id="title.deals.detailsLink" defaultMessage="Link to Deal Details" />
          </span>
        ),
        accessor: 'template',
        Cell: ({
          cell: {
            value,
            row: {
              values: { status, template, templateType },
            },
          },
        }: ColumnsCellType) => {
          const selectedTemplateData = documentTemplatesData!.getDocumentTemplates.find(
            (fetchedTemplate: any) => fetchedTemplate.id === template.id
          );

          return (
            <div
              onClick={(evt: MouseEvent) => {
                evt.stopPropagation();
              }}
              className={styles.actionsTableColumn}
            >
              {!template.fromOrganization ? (
                <Controls
                  status={status || ''}
                  editPermission={isAdmin || canEdit}
                  selectedTemplateData={selectedTemplateData}
                  teamId={teamId}
                  onSuccess={templateIsUpdated}
                  templateType={TemplateType.DOCUMENT}
                />
              ) : (
                <div className={styles.templatesTooltipContainer}>
                  <Tooltip placement="bottom" testId="organization-template-tooltip">
                    This Template is available due to your active membership in {template.organizationName}, it cannot
                    be edited.
                  </Tooltip>
                </div>
              )}
            </div>
          );
        },
        disableSortBy: true,
      },
    ],
    [templateIsUpdated, canEdit, isAdmin, documentTemplatesData, teamId, permissions]
  );

  const addTemplateActions = () => {
    const showSubscriptionModal = () => {
      setSubscriptionModalActive(true)
    }
    return [
      {
        text: AddTemplateActionStatus.IMPORT_FROM_WORD,
        onClick: creatableTemplateTypes && creatableTemplateTypes.includes(CreatedTemplateTypes.IMPORTED_WORD)
          ? handleDocFileUploadModal
          : showSubscriptionModal,
        isAvailable: true,
      },
      {
        text: AddTemplateActionStatus.UPLOADED_PDF,
        onClick: creatableTemplateTypes && creatableTemplateTypes.includes(CreatedTemplateTypes.IMPORTED)
          ? handleFileUploadModal
          : showSubscriptionModal,
        isAvailable: true,
      },
      {
        text: AddTemplateActionStatus.BLANK_TEMPLATE,
        onClick: creatableTemplateTypes && creatableTemplateTypes.includes(CreatedTemplateTypes.BLANK)
          ? () => onAddTemplateShow(TemplateType.DOCUMENT)
          : showSubscriptionModal,
        isAvailable: true,
      },
    ];
  };

  const addTemplateActionsDropdown = (actions: CreateTemplateDropdownActionsType[]) => {
    return actions
      .filter(action => action.isAvailable)
      .map((action, index) => (
        <div className={styles.documentDropdownContainer} key={index}>
          <Fragment key={index}>
            <Dropdown.Item as={Button} variant="link" onClick={() => action.onClick()}>
              {action.text}
            </Dropdown.Item>
          </Fragment>
        </div>
      ));
  };

  const onTemplateDetail = (template: any) => {
    const templateUrl = fillPlaceholders(
      !template.fromOrganization ? paths.templates.document : paths.templates.orgTemplate,
      {
        templateId: template.id,
        teamId,
      }
    );

    navigate(templateUrl);
  };

  const teamTemplatesData = useMemo(() => {
    const filteredData =
      allTemplates
        .filter((template: any) => {
          const templateType = getTemplateType(template);

          const hasTemplateActualStatus = filterStatuses.has(template.status);
          const hasTemplateActualTemplateType = filterTemplateTypes.has(templateType);

          const hasTemplateFilteredStatus = filterStatuses.size && filterStatuses.size < statusOptions.length;
          const hasTemplateFilteredType =
            filterTemplateTypes.size && filterTemplateTypes.size < templateTypeOptions.length;

          if (!filterCreators.has(template.team?.name) && !filterCreators.has(`${template.team?.name} (Your Team)`)) {
            return false;
          }

          return !(
            (filterText && !fuzzyInputSearch({ filteredText: filterText, searchValue: template.name })) ||
            (hasTemplateFilteredStatus && !hasTemplateActualStatus) ||
            (hasTemplateFilteredType && !hasTemplateActualTemplateType)
          );
        })
        .map((template: any) => {
          const templateType = getTemplateType(template);

          return {
            template: {
              id: template.id,
              fromOrganization: template.fromOrganization,
              organizationName: template.team?.organization?.name,
            },
            name: formatMessage({
              id: 'format.name',
              defaultMessage: template.name,
            }),
            status: template.status,
            updatedAt: template.updatedAt,
            templateType: templateType,
            templateCreator: template.team?.name || '',

            onRowClick: () => {
              const templateUrl = fillPlaceholders(
                !template.fromOrganization ? paths.templates.document : paths.templates.orgTemplate,
                {
                  templateId: template.id,
                  teamId,
                }
              );

              navigate(templateUrl);
            },
          };
        }) ?? [];

    filteredData.sort((a: any, b: any) => {
      switch (sorting) {
        case 'ascName':
          return a.name.localeCompare(b.name);
        case 'descName':
          return b.name.localeCompare(a.name);
        case 'statusActive':
          return a.status.localeCompare(b.status);
        case 'statusArchived':
          return b.status.localeCompare(a.status);
        case 'dateToLatest':
          return b?.updatedAt?.localeCompare(a?.updatedAt);
        case 'dateToNewest':
          return a?.updatedAt?.localeCompare(b?.updatedAt);

        default:
          return a?.updatedAt && b?.updatedAt && b.updatedAt.localeCompare(a.updatedAt);
      }
    });

    return filteredData;
  }, [
    allTemplates,
    formatMessage,
    filterText,
    filterStatuses,
    filterCreators,
    filterTemplateTypes,
    allCreators.length,
    teamId,
    sorting,
    navigate,
  ]);

  const sortBy = useMemo(() => {
    switch (sorting) {
      case 'ascName':
        return {
          id: 'name',
          desc: false,
        };
      case 'descName':
        return {
          id: 'name',
          desc: true,
        };
      case 'statusActive':
        return {
          id: 'status',
          desc: false,
        };
      case 'statusArchived':
        return {
          id: 'status',
          desc: true,
        };
      case 'dateToLatest':
        return {
          id: 'updatedAt',
          desc: false,
        };
      case 'dateToNewest':
        return {
          id: 'updatedAt',
          desc: true,
        };

      default:
        return {
          id: 'updatedAt',
          desc: false,
        };
    }
  }, [sorting]);

  const table = useTable(
    {
      data: teamTemplatesData,
      //@ts-ignore
      columns,
      initialState: {
        sortBy: [sortBy],
      },
    },
    useSortBy,
    usePagination
  );

  if (isDocumentTemplatesDataLoading || isUserOrgsTemplatesDataLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet title={formatMessage({ id: 'title.templates', defaultMessage: 'Templates' })} />
      {(!hasAccessToTemplates || isSubscriptionModalActive) && (
        <>
          <SubscriptionRequiredModal
            isOpen={!hasAccessToTemplates || isSubscriptionModalActive}
            onClose={() => {
              setSubscriptionModalActive(false);
              navigate(fillPlaceholders(paths.templates.landing, { teamId }));
            }}
          />
        </>
      )}
      <ContentHeader>
        <Row>
          <Col lg={6}>
            <div data-testid="deals-header" role={'heading'} className={'ml-3'}>
              <FormattedMessage id="title.templates" defaultMessage="Templates" />
            </div>
          </Col>
        </Row>
      </ContentHeader>
      <ContentBody>
        <Row className="mb-3">
          <Col className={'mx-3 d-flex justify-content-between non-mobile-flex'}>
            <div className={'d-flex flex-fill'}>
              <SearchInput
                className={classNames(styles.searchInboxBox, 'mr-3')}
                value={filterText}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilterText(event.currentTarget.value)}
                placeholder={formatMessage({
                  id: 'deals.filter.title.placeholder',
                  defaultMessage: 'Search template name',
                })}
                aria-label={formatMessage({
                  id: 'deals.filter.title.label',
                  defaultMessage: 'Name',
                })}
              />

              <div className={classNames(styles.filterDropdownContainer, 'mr-3')}>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-filter-templateType" as={DropdownToggle}>
                    <FormattedMessage
                      id="deals.filter.templateType.label-readout"
                      defaultMessage={`{filterCount, select,
                      0 {Type (all)}
                      1 {Type ({templateType, select,
                        PDF {PDF}
                        FULLY_EDITABLE {Fully Editable}
                        RESTRICTED {Restricted}
                        other {{templateType}}
                      })}
                      other {{filterCount} types}
                    }`}
                      values={{
                        filterCount:
                          filterTemplateTypes.size === templateTypeOptions.length ? 0 : filterTemplateTypes.size,
                        templateType: filterTemplateTypes.size ? filterTemplateTypes.values().next().value : '',
                      }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <DropdownItem highlightable>
                      <Checkbox
                        checked={filterTemplateTypes.size === templateTypeOptions.length}
                        indeterminate={
                          filterTemplateTypes.size > 0 && filterTemplateTypes.size < templateTypeOptions.length
                        }
                        id="dropdown-filter-templateTypes-_all"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const checkbox = event.currentTarget;
                          if (checkbox.checked) {
                            setFilterTemplateTypes(new Set(Object.keys(templateTypeMessages)));
                          } else {
                            setFilterTemplateTypes(new Set());
                          }
                        }}
                        label={formatMessage({
                          id: 'deals.filter.templateTypes.all-templateTypes',
                          defaultMessage: 'All Types',
                        })}
                      />
                    </DropdownItem>
                    <DropdownBody>
                      {templateTypeOptions.map(({ value: templateType, label }) => (
                        <DropdownItem key={templateType} highlightable>
                          <Checkbox
                            key={templateType}
                            value={templateType}
                            checked={filterTemplateTypes.has(templateType)}
                            id={`dropdown-filter-templateTypes-${slugify(templateType)}`}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const checkbox = event.currentTarget;
                              const newSet = new Set(filterTemplateTypes);
                              if (checkbox.checked) newSet.add(checkbox.value);
                              else newSet.delete(checkbox.value);
                              setFilterTemplateTypes(newSet);
                            }}
                            label={<FormattedMessage {...label} />}
                          />
                        </DropdownItem>
                      ))}
                    </DropdownBody>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className={classNames(styles.filterDropdownContainer, 'mr-3')}>
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-filter-creator"
                    data-testid="dropdown-filter-creator"
                    as={DropdownToggle}
                    /*
                  // @ts-ignore */
                    className="w-100 overflow-hidden"
                  >
                    <FormattedMessage
                      id="deals.filter.creator.label-readout"
                      defaultMessage={`{filterCount, select,
                      0 {Creator (all)}
                      1 {Creator ({creator})}
                      other {{filterCount} Teams}
                    }`}
                      values={{
                        filterCount: filterCreators.size === allCreators.length ? 0 : filterCreators.size,
                        creator: filterCreators.size ? filterCreators.values().next().value : '',
                      }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <DropdownItem highlightable>
                      <Checkbox
                        checked={filterCreators.size === allCreators.length}
                        indeterminate={filterCreators.size > 0 && filterCreators.size < allCreators.length}
                        id="dropdown-filter-creators-_all"
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          const checkbox = event.currentTarget;
                          if (checkbox.checked) {
                            setFilterCreators(new Set(allCreators));
                          } else {
                            setFilterCreators(new Set());
                          }
                        }}
                        label={formatMessage({
                          id: 'deals.filter.creator.all-creators',
                          defaultMessage: 'All Creators',
                        })}
                      />
                    </DropdownItem>
                    <DropdownBody>
                      {allCreators.map((creator: any) => (
                        <DropdownItem key={creator} highlightable>
                          <Checkbox
                            key={creator}
                            value={creator}
                            checked={filterCreators.has(creator)}
                            id={`dropdown-filter-cities-${slugify(creator)}`}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                              const checkbox = event.currentTarget;
                              const newSet = new Set(filterCreators);
                              if (checkbox.checked) newSet.add(checkbox.value);
                              else newSet.delete(checkbox.value);
                              setFilterCreators(newSet);
                            }}
                            label={creator}
                          />
                        </DropdownItem>
                      ))}
                    </DropdownBody>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className={classNames(styles.filterDropdownContainer, 'mr-3')}>
                <Dropdown>
                  <Dropdown.Toggle id="dropdown-filter-status" as={DropdownToggle}>
                    <FormattedMessage
                      id="deals.filter.status.label-readout"
                      defaultMessage={`{filterCount, select,
                      0 {Status (any)}
                      1 {Status ({status, select,
                        active {Active}
                        archived {Archived}
                        other {{status}}
                      })}
                      other {{filterCount} statuses}
                    }`}
                      values={{
                        filterCount: filterStatuses.size === statusOptions.length ? 0 : filterStatuses.size,
                        status: filterStatuses.size ? filterStatuses.values().next().value : '',
                      }}
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <DropdownItem highlightable>
                      <Checkbox
                        checked={filterStatuses.size === statusOptions.length}
                        indeterminate={filterStatuses.size > 0 && filterStatuses.size < statusOptions.length}
                        id="dropdown-filter-statuses-_all"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const checkbox = event.currentTarget;
                          if (checkbox.checked) {
                            setFilterStatuses(new Set(Object.keys(templateStatusMessages)));
                          } else {
                            setFilterStatuses(new Set());
                          }
                        }}
                        label={formatMessage({
                          id: 'deals.filter.status.all-statuses',
                          defaultMessage: 'Any status',
                        })}
                      />
                    </DropdownItem>
                    <DropdownBody>
                      {statusOptions.map(({ value: status, label }) => (
                        <DropdownItem key={status} highlightable>
                          <Checkbox
                            key={status}
                            value={status}
                            checked={filterStatuses.has(status)}
                            id={`dropdown-filter-statuses-${slugify(status)}`}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                              const checkbox = event.currentTarget;
                              const newSet = new Set(filterStatuses);
                              if (checkbox.checked) newSet.add(checkbox.value);
                              else newSet.delete(checkbox.value);
                              setFilterStatuses(newSet);
                            }}
                            label={<FormattedMessage {...label} />}
                          />
                        </DropdownItem>
                      ))}
                    </DropdownBody>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className={classNames(styles.addTemplateActionsContainer, 'mr-3')}>
              {isTemplateEditable && (
                <>
                  <div className={styles.addTemplateActionsContainer__dropdown}>
                    <Dropdown>
                      <Dropdown.Toggle id="dropdown-deal-actions" variant={'secondary'} as={DropdownToggleOrange}>
                        <FormattedMessage id="deals.actions" defaultMessage="Add New Template" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <DropdownBody>{addTemplateActionsDropdown(addTemplateActions())}</DropdownBody>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <Modal
                    show={showAddTemplate}
                    onHide={onAddTemplateModalHide}
                    size="sm"
                    dialogClassName={styles.createTemplateModalContainer}
                  >
                    <div className={styles.templateModal}>
                      <Modal.Header
                        closeButton
                        closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}
                      >
                        <Modal.Title>
                          <header className={styles.templateModalHeader}>
                            <FormattedMessage
                              id="title.add-team-member"
                              defaultMessage={`Create ${AddTemplateActionStatus.BLANK_TEMPLATE}`}
                            />
                          </header>
                        </Modal.Title>
                      </Modal.Header>
                      <AddNewTemplateFormik
                        bodyContainer={Modal.Body}
                        actionsContainer={Modal.Footer}
                        renderSecondaryAction={() => (
                          <Button variant="link" className="text-secondary" onClick={onAddTemplateModalHide}>
                            <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
                          </Button>
                        )}
                        onSuccess={templateIsUpdated}
                        templateFormEditMode={false}
                        templateType={selectedTemplateType}
                      />
                    </div>
                  </Modal>
                  <Modal show={showEditTemplate} onHide={() => setShowEditTemplate(false)} size="sm">
                    <div className={styles.templateModal}>
                      <Modal.Header
                        closeButton
                        closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}
                      >
                        <Modal.Title>
                          <header>
                            <FormattedMessage id="title.add-team-member" defaultMessage="Edit Template" />
                          </header>
                        </Modal.Title>
                      </Modal.Header>
                    </div>
                  </Modal>
                </>
              )}
            </div>
          </Col>
          <Col className={'mx-3 d-flex flex-column justify-content-between mobile-flex'}>
            {isTemplateEditable && (Array.isArray(creatableTemplateTypes) && creatableTemplateTypes.length > 0) && (
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-deal-actions"
                  variant={'secondary'}
                  as={DropdownToggleOrange}
                  className="mobileHeaderBtn"
                >
                  <FormattedMessage id="deals.actions" defaultMessage="Add New Template" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <DropdownBody>{addTemplateActionsDropdown(addTemplateActions())}</DropdownBody>
                </Dropdown.Menu>
              </Dropdown>
            )}
            <div className={'d-flex flex-fill mt-3'}>
              <SearchInput
                iconClassName="mobileSearch__icon"
                inputClassName="mobileSearch"
                className="mobileSearch__container"
                value={filterText}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFilterText(event.currentTarget.value)}
                placeholder={formatMessage({
                  id: 'deals.filter.title.placeholder',
                  defaultMessage: 'Search template name',
                })}
                aria-label={formatMessage({
                  id: 'deals.filter.title.label',
                  defaultMessage: 'Name',
                })}
              />

              <Button className="filterBtn" onClick={() => setShowFilter(true)} data-testid="mobileFilterBtn">
                <FilterIcon width={24} height={24} />
              </Button>
            </div>
          </Col>
        </Row>

        <Row className={styles.contentTable}>
          <Col className={'mr-3 ml-3'}>
            <div>
              {teamTemplatesData.length ? (
                <>
                  <div className="non-mobile">
                    <Table striped isStickyHeader isActionColumn table={table} />
                  </div>
                  <div className="mobile">
                    <MobileTable>
                      {teamTemplatesData?.map((template: any) => (
                        <MobileTemplateItem
                          key={`mobile-template-${template.template.id}`}
                          template={template}
                          isAdmin={isAdmin}
                          canEdit={canEdit}
                          teamId={teamId}
                          selectedTemplate={documentTemplatesData?.getDocumentTemplates?.find(
                            tmp => tmp.id === template.template.id
                          )}
                          templateIsUpdated={templateIsUpdated}
                          onClick={() => onTemplateDetail(template.template)}
                        />
                      ))}
                    </MobileTable>
                  </div>
                </>
              ) : (
                <Container className="mt-5">
                  <div className="lead text-center">
                    <h2>
                      <FormattedMessage id="title.templates-history" defaultMessage="No Templates" />
                    </h2>
                    <FormattedMessage
                      id="phrase.templates.no-templates"
                      defaultMessage="Your team's templates will appear here."
                    />
                  </div>
                </Container>
              )}
            </div>
          </Col>
        </Row>
      </ContentBody>
      <FileUploadModal
        onClose={() => dispatch(setActiveModal(null))}
        isOpen={activeModal === ModalActionsEnum.UPLOAD_FILE || activeModal === ModalActionsEnum.UPLOAD_DOC_FILE}
        teamId={teamId}
        templateUpload
      />
      <FilterSidebar
        isOpen={showFilter}
        filterTypes={filterTemplateTypes}
        filterStatuses={filterStatuses}
        filterCreators={filterCreators}
        onClose={() => setShowFilter(!showFilter)}
        allCreators={allCreators}
        setSorting={setSorting}
        setFilterTypes={setFilterTemplateTypes}
        setFilterStatuses={setFilterStatuses}
        setFilterCreators={setFilterCreators}
      />
    </>
  );
};

export default TemplatesLandingPage;
