export const exportPdfConfig = (fileName: string, headerHTML?: string, footerHTML?: string) => {
  return {
    stylesheets: [`${process.env.PUBLIC_URL}/styles.css`],
    fileName: fileName,
    converterOptions: {
      format: 'Letter',
      margin_top: '1in',
      margin_bottom: '1in',
      margin_right: '0.925in',
      margin_left: '0.925in',
      page_orientation: 'portrait',
      footer_html: footerHTML && `<div class="styled">${footerHTML}</div>`,
      header_html: headerHTML && `<div class="styled">${headerHTML}</div>`,
      header_and_footer_css: `#header,
      #footer {
        margin-left: -3mm;
        margin-right: -3mm;
        font-family: Arial, Helvetica, sans-serif;
        transform: scale(0.758);
      }

      .image {
        display: table;
        clear: both;
        text-align: center;
        margin: 0.9em auto;
        min-width: 50px;
      }

      .image img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        min-width: 100%;
        height: auto;
      }

      .image-inline {
        display: inline-flex;
        max-width: 100%;
        align-items: flex-start;
      }

      .image-inline picture {
        display: flex;
      }

      .image-inline picture,
      .image-inline img {
        flex-grow: 1;
        flex-shrink: 1;
        max-width: 100%;
      }

      img.image_resized {
        height: auto;
      }

      .image.image_resized {
        max-width: 100%;
        display: block;
        box-sizing: border-box;
      }

      .image.image_resized img {
        width: 100%;
      }

      .image.image_resized > figcaption {
        display: block;
      }

      .image-style-block-align-right {
        margin-right: 0;
        margin-left: auto;
      }

      .image-style-block-align-left {
        margin-right: auto;
        margin-left: 0;
      }

      .styled {
        line-height: 20px;
        word-wrap: break-word;
      }

      .table {
        margin: auto;
      }

      table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        max-width: 100%;
        height: 100%;
        display: table;
      }

      table tbody td:first-child {
        border-top-left-radius: 0.125rem;
        border-bottom-left-radius: 0.125rem;
      }

      table td,
      table th {
        min-width: 2em;
        padding: 0.4em;
        overflow-wrap: break-word;
        position: relative;
      }`,
    },
    dataCallback: ( editor: any ) => {
      const wrapUnderscores = (rawHtml: string): string => {
        // Match 4 or more underscores
        const pattern = /(_{5,})/g;
        const replaceWith = '<span class="underscore_line">$1</span>';

        return rawHtml.replace(pattern, replaceWith);
      }

      return wrapUnderscores(editor.getData());
    },
  };
};

export const listProperties = {
  properties: {
    styles: true,
    startIndex: true,
    reversed: true,
  },
};

export const TemplateEditorErrors = {
  COMMENT_THREAD_UPDATE: {
    id: 'LeaseDocumentCommentsDrawer.comment-thread-update-error',
    defaultMessage: 'Unable to update comment thread',
  },
  COMMENT_UPDATE: {
    id: 'LeaseDocumentCommentsDrawer.comment-update-error',
    defaultMessage: 'Unable to update comment',
  },
  COMMENT_THREAD_RESOLVE: {
    id: 'LeaseDocumentCommentsDrawer.comment-thread-resolve-error',
    defaultMessage: 'Unable to resolve comment thread',
  },
  COMMENT_CREATE: {
    id: 'LeaseDocumentCommentsDrawer.comment-creation-error',
    defaultMessage: 'Unable to create comment',
  },
  COMMENT_THREAD_CREATE: {
    id: 'LeaseDocumentCommentsDrawer.comment-thread-creation-error',
    defaultMessage: 'Unable to create comment thread',
  },
  COMMENT_REOPEN: {
    id: 'LeaseDocumentCommentsDrawer.comment-reopen-error',
    defaultMessage: 'Unable to reopen comment thread',
  },
  COMMENT_REMOVE: {
    id: 'LeaseDocumentCommentsDrawer.comment-remove-error',
    defaultMessage: 'Unable to remove comment',
  },
  COMMENT_THREAD_REMOVE: {
    id: 'LeaseDocumentCommentsDrawer.comment-thread-remove-error',
    defaultMessage: 'Unable to remove comment thread',
  },
  RENAME_TITLE: {
    id: 'lease-wizard.header.hover.title',
    defaultMessage: 'Rename title',
  },
};

export const LeaseEditorErrors = {
  NO_POSSESSION: {
    id: 'LeaseDocumentError.no-possession',
    defaultMessage:
      'This document is currently in the possession of the other party. You and your teammates will not be able to edit until possession has been passed back to you.',
  },
  IS_LOCKED: {
    id: 'LeaseDocumentError.is-locked',
    defaultMessage:
      'This document is currently being edited by another user of your team. The contents may be changing, and you will not be able to edit until they have finished.',
  },
  TEMPLATE_IS_LOCKED: {
    id: 'LeaseTemplateError.is-locked',
    defaultMessage:
      'This template is currently being edited by another user of your team. The contents may be changing, and you will not be able to edit until they have finished.',
  },
  IS_PREPARING_TO_SIGN: {
    id: 'LeaseDocumentError.is-preparing-to-sign',
    defaultMessage:
      'This document is being prepared for signing and cannot be accessed until this is completed by your counterparty',
  },
};

export const successSendDocumentMessage = {
  id: 'lease-document-wizard-preview-send-offer.send.success',
  defaultMessage: 'Success! Your document has been shared with all parties on the deal.',
};

export const givePossessionSuccessMessage = {
  id: 'lease-document-wizard-give-possession-offer.send.success',
  defaultMessage: 'The document has been successfully sent for counterparty review.',
};

export const successGrantAccessMessage = {
  id: 'document-grant-access.success',
  defaultMessage: 'Success! Your deal has been shared with {email}.',
};

export const MIN_WINDOW_WIDTH_FOR_WIDE_SIDEBAR = 1500;

export const formattingOptions = [
  'heading',
  'Bold',
  'Italic',
  'Underline',
  'Highlight',
  'FontSize',
  'FontFamily',
  'FontColor',
  'fontBackgroundColor',
  'Indent',
  'Outdent',
  'alignment',
  'numberedList',
  'bulletedList',
]

export const toolbarItems = (isDocumentCreator: boolean, userCommentArchivePermission: boolean) => {
  return [
    'revisionHistory',
    '|',
    'Undo',
    'Redo',
    'ExportPdf',
    isDocumentCreator && 'importWord',
    isDocumentCreator && 'editHeaderFooter',
    '|',
    'pageNavigation',
    '|',
    'formattingOptions',
    '|',
    {
      label: 'Insert',
      icon: false,
      items: ['imageUpload', 'insertTable', 'pageBreak', 'insertInlineCheckbox',],
    },
    '|',
    'propdocsTrackChanges',
    '|',
    // TODO: Re-enable when it is fixed
    // userCommentArchivePermission && 'commentsArchive',
    'findAndReplace',
  ];
};

export const restrictedFormattingOptions = [
  'heading',
  'Bold',
  'Italic',
  'Underline',
  'Strikethrough',
  'Highlight',
  'FontSize',
  'FontFamily',
  'FontColor',
]

export const restrictedToolbarItems = (isDocumentCreator: boolean) => {
  return [
    'revisionHistory',
    '|',
    'Undo',
    'Redo',
    'ExportPdf',
    isDocumentCreator && 'editHeaderFooter',
    '|',
    'pageNavigation',
    '|',
    'formattingOptions',
    '|',
    {
      label: 'Insert',
      icon: false,
      items: ['imageUpload', 'insertInlineCheckbox',],
    },
    '|',
    'propdocsTrackChanges',
    '|',
    'findAndReplace',
  ];
};

export const restrictedToolbar = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'highlight',
  'fontSize',
  'fontFamily',
  'fontColor',
  'insertImage',
  'resizeImage',
  'uploadImage',
  'insertInlineCheckbox',
  'toggleInlineCheckbox',
];

export const ShareWithUnresolvedSuggestionsWarning = {
  title: 'Suggestions still pending',
  text:
    'This document has {num} counterparty suggestion(s) pending which must be accepted or discarded by your team before the document can be signed.',
};
export enum DocumentSourceEnum {
  APP = 'app',
  IMPORTED = 'imported',
}

export const TRACK_CHANGES_BUTTON_LABEL = 'Track changes';

export const DOCUMENT_RECEIVER_WARNING =
  `Edit document to make suggestions, highlight to comment, and then send to review or sign when you're ready.`

export const DUPLICATE_EMAIL_WARNING = 'This email is already a member of this deal team.';
export const PENDING_EMAIL_WARNING = 'This email has a pending invite to this deal team already.';
export const ORG_MEMBER_EMAIL_WARNING = 'This email is a member of this workspace already.';
