import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ClauseTemplateItem } from './components';
import { ClauseTemplate } from './clauseTemplateSidebarContainer';
import SearchInput from '../../../../search-input';
import { fillPlaceholders, paths } from '../../../../../routing';
import { selectLeaseDocumentTeam } from '../../../../../store/lease-document/selectors/lease-document.selectors';
import { useTotango } from '../../../../../shared/hooks/useTotango';
import { TOTANGO_EVENT_TYPES } from '../../../../../utils/totango';
import styles from './styles.module.scss';

interface ClauseTemplatesSidebarSidebarProps {
  clauseTemplates: ClauseTemplate[];
  isDisabled: boolean;
}

const ClauseTemplatesSidebar = ({ clauseTemplates, isDisabled }: ClauseTemplatesSidebarSidebarProps) => {
  const teamId = useSelector(selectLeaseDocumentTeam);
  const { formatMessage } = useIntl();
  const [filterText, setFilterText] = useState('');
  const sendTotango = useTotango();

  const handleDrag = useCallback(
    (event: any) => {
      if (isDisabled) return;

      const target = event.target.nodeType === 1 ? event.target : event.target.parentElement;
      const draggable = target.closest('[draggable]');

      if (target.nodeName === 'DIV') {
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      const body = draggable.getAttribute('data-body');
      event.dataTransfer.setData('text/html', body);
      sendTotango(TOTANGO_EVENT_TYPES.clauseInserted);
    },
    [isDisabled, sendTotango]
  );

  const openClausesInNewTab = () => {
    const navigateUrl = teamId && fillPlaceholders(paths.clauses.landing, { teamId });
    navigateUrl && window.open(navigateUrl);
  };

  useEffect(() => {
    if (isDisabled) return;

    const clauseTemplatesContainer = document.querySelector('#clause-templates-container');
    clauseTemplatesContainer!.addEventListener('dragstart', handleDrag);

    return () => {
      clauseTemplatesContainer!.removeEventListener('dragstart', handleDrag);
    };
  }, [handleDrag, isDisabled]);

  const renderClauseTemplatesItems = useMemo(
    () =>
      clauseTemplates
        .filter(clauseTemplate => {
          const filterTextLower = filterText?.toLocaleLowerCase();
          return clauseTemplate.title.toLocaleLowerCase().includes(filterTextLower);
        })
        .map((clauseTemplate, index) => (
          <ClauseTemplateItem
            title={clauseTemplate.title}
            key={clauseTemplate.id}
            index={index}
            id={clauseTemplate.id}
            body={clauseTemplate.body}
          />
        )),
    [clauseTemplates, filterText]
  );

  return (
    <div className={classNames(styles.wrapper, { [styles['wrapper-disabled']]: isDisabled })}>
      <h2>Clauses</h2>
      <SearchInput
        className={styles.searchInput}
        value={filterText}
        onChange={(event: any) => setFilterText(event.currentTarget.value)}
        placeholder={formatMessage({
          id: 'clauseTemplates.filter.title',
          defaultMessage: 'Search clause by name',
        })}
      />
      {!!clauseTemplates.length && !renderClauseTemplatesItems.length && (
        <p className="text-center mt-5">No clauses match your filters </p>
      )}
      <div id="clause-templates-container" className={styles['templates-container']}>
        {!!clauseTemplates.length && <>{renderClauseTemplatesItems}</>}
        {!clauseTemplates.length && (
          <>
            <p className="text-center">No clauses available </p>
            {teamId && (
              <div className="text-center">
                <NavLink to="#" onClick={openClausesInNewTab} className="font-weight-bold">
                  <FormattedMessage id="cta.go-to.overview" defaultMessage="Add a Clause Now" />
                </NavLink>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ClauseTemplatesSidebar;
