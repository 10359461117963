import { useCallback } from 'react';
import sendTotangoEvent, { TotangoUtilProps } from '../../utils/totango';
import { useAuth } from '../../auth';
import { useTeamInfo } from '../../team-id-context';

export const useTotango = () => {
  const [{ user }] = useAuth();
  const { teamName, adminUser, teamUUID } = useTeamInfo();

  const sendTotango = useCallback(
    (eventType: { name: string; module: string }, overrides?: Partial<TotangoUtilProps>) => {
      const event = { eventType, user, teamName, teamUUID, adminUser, ...overrides };
      sendTotangoEvent(event);
    },
    [user, teamName, adminUser]
  );

  return sendTotango;
};
