import React, { ChangeEvent, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { queries, mutations } from '../../api';
import { useNotifications } from '../../notifications';
import { useTeamInfo } from '../../team-id-context';
import { Dropdown } from '../dropdown';
import { Permissions } from '../../shared/constants/permissions';
import { DealsDropdown, Toggle } from './components';
import { getTeamById } from '../../api/queries';
import { Permission } from '../../gql/graphql';
import { useTotango } from '../../shared/hooks/useTotango';
import { TOTANGO_EVENT_TYPES } from '../../utils/totango';
import styles from './styles.module.scss';

interface SelectDealsProps {
  dealsForTeam: any[];
  readOnly?: boolean;
  selectedPermissions?: any[];
  whitelistPermissionId: string;
  userRowData: {
    permissions: Permission[];
    id: string;
    isAdmin: boolean;
    invitationId: string | null;
    isWhitelisted: boolean;
    dealIds: string[];
    email: string;
  };
}

const SelectDeals = ({ dealsForTeam, readOnly = false, whitelistPermissionId, userRowData }: SelectDealsProps) => {
  const { teamId } = useTeamInfo();
  const [inviteNewTeamMemberToDeal] = useMutation(mutations.inviteNewTeamMembersByEmail);
  const [removeTeamMemberFromDeal] = useMutation(mutations.removeTeamMemberFromDeal);
  const [updateUserPermissions] = useMutation(mutations.updateUserPermissions, {});
  const [updatePendingInvitation] = useMutation(mutations.updatePendingInvitation, {
    refetchQueries: [
      {
        query: getTeamById,
        variables: { teamId },
      },
    ],
  });
  const [, { error: notifyError }] = useNotifications();
  const sendTotango = useTotango();
  const [isUpdatingDeal, setUpdatingDeal] = useState(false);

  const { isWhitelisted, email, id: userId, dealIds, permissions: selectedPermissions, invitationId } = userRowData;
  const selectedDeals = useMemo(() => {
    return dealIds.filter(dealId => dealsForTeam.find((deal: any) => deal.id === dealId));
  }, [dealsForTeam, dealIds]);

  const handleChecked = async (checkbox: any, dealId: any, userPermissions: any) => {
    if (invitationId) {
      await updatePendingInvitation({
        variables: {
          id: invitationId,
          dealIds: [...selectedDeals, dealId],
          permissionIds: userPermissions[0].permissionIds,
        },
      });
    } else {
      await inviteNewTeamMemberToDeal({
        variables: {
          teamId,
          userId,
          userPermissions,
          emails: [email],
          defaultDeals: [dealId],
        },
        refetchQueries: [
          {
            query: queries.getDealsForTeam,
            variables: {
              teamId,
            },
          },
        ],
      });
      sendTotango(TOTANGO_EVENT_TYPES.teamMemberInvited);
    }
  };

  const handleUncheck = async (checkbox: any, dealId: any, userPermissions: any) => {
    if (invitationId) {
      const updatedDealIds = selectedDeals.filter(id => id !== dealId);
      await updatePendingInvitation({
        variables: {
          id: invitationId,
          dealIds: updatedDealIds,
          permissionIds: userPermissions[0].permissionIds,
        },
      });
    } else {
      await removeTeamMemberFromDeal({
        variables: {
          userId,
          dealId,
        },
        refetchQueries: [
          {
            query: queries.getTeamById,
            variables: {
              teamId,
            },
          },
        ],
      });
    }
  };

  return (
    <Dropdown>
      <Toggle
        id={`dropdown-select-deals-t${teamId}-u${userId}`}
        totalCount={dealsForTeam.length}
        selectedCount={selectedDeals?.length || 0}
        isWhitelisted={isWhitelisted}
      />

      <Dropdown.Menu
        as={DealsDropdown}
        className={styles.dealDropdown}
        isWhitelisted={isWhitelisted}
        whitelistValue={whitelistPermissionId}
        dropdownId={`dropdown-select-deals-t${teamId}-u${userId}`}
        onWhitelistChange={async (event: ChangeEvent<HTMLInputElement>) => {
          const checkbox = event.currentTarget;
          const userPermissionsIds = selectedPermissions.map(({ id }) => id);
          const userPermissions = [
            {
              email,
              permissionIds: userPermissionsIds,
            },
          ];
          try {
            await updateUserPermissions({
              variables: {
                userId,
                teamId,
                permissionUpdates: [
                  {
                    permissionKey: Permissions.Whitelist,
                    allowed: checkbox.checked,
                  },
                ],
              },
              refetchQueries: [
                {
                  query: queries.getDealsForTeam,
                  variables: {
                    teamId,
                  },
                },
                {
                  query: getTeamById,
                  variables: { teamId },
                },
              ],
            });

            if (!checkbox.checked) {
              const deals = dealsForTeam.filter(deal => dealIds.find(dealId => deal.id === dealId));
              setUpdatingDeal(true);
              for (let deal of deals) {
                await handleUncheck(checkbox, deal.id, userPermissions);
              }
              setUpdatingDeal(false);
            }
          } catch (error) {
            notifyError({ message: error });
          }
        }}
        whitelistChecked={isWhitelisted}
        whitelistDisabled={isUpdatingDeal || readOnly}
        dealDisabled={isUpdatingDeal}
        dealChecked={(deal: { id: string; dealAccessUsers: string[] }) => {
          return (
            isWhitelisted ||
            (isUpdatingDeal ? isWhitelisted : selectedDeals?.includes(deal.id) ?? deal.dealAccessUsers.includes(userId))
          );
        }}
        dealValue={(deal: { id: string }) => deal.id}
        onDealChange={async (event: ChangeEvent<HTMLInputElement>, deal: any) => {
          const checkbox = event.currentTarget;
          const userPermissionsIds = selectedPermissions.map(({ id }) => id);
          const userPermissions = [
            {
              email,
              permissionIds: userPermissionsIds,
            },
          ];
          try {
            if (checkbox.checked) {
              await handleChecked(checkbox, deal.id, userPermissions);
            } else {
              await handleUncheck(checkbox, deal.id, userPermissions);
            }
          } catch (error) {
            notifyError({ message: error });
          }
        }}
      >
        {dealsForTeam}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SelectDeals;
