import React, { useState, useRef, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import classNames from 'classnames';
import Dropdown from 'react-bootstrap/Dropdown';
import { DropdownToggle } from '../dropdown';
import { queries, usePartyIds } from '../../api';
import { getTeamById } from '../../api/queries';
import { useAuth } from '../../auth';
import { useUserRestrictions } from '../../auth/user-restrictions';
import { A, paths } from '../../routing';
import { useTeamInfo } from '../../team-id-context';
import { get as getLatestTeamId, set } from '../../utils/latest-team-id';
import CreateNewTeamModal from '../shared/modals/createNewTeamModal/CreateNewTeamModal';
import { ReactComponent as WorkspacesIcon } from '../../shared/icons/workspaces_icon.svg';
import { SubscriptionRequiredModal } from '../../pages';
import styles from './styles.module.scss';

const CurrentWorkspaceIndicator = () => {
  const [{ authLoading: isAuthLoading, user }] = useAuth();
  const { canCreateTeam } = useUserRestrictions();
  const teamId = useTeamInfo().teamId ?? getLatestTeamId(user.id);
  const [showAddNewTeamModal, setShowAddNewTeamModal] = useState(false);
  const [showSubscriptionRequiredModal, setSubscriptionRequiredModal] = useState(false);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(teamId);
  const [refetchUserRestrictions] = useLazyQuery(queries.getRestrictionsByCurrentUser, {
    fetchPolicy: 'network-only',
    variables: {
      teamId,
    },
  });

  const { loading: isPartyIdsLoading, partyIds } = usePartyIds();
  const { loading: isTeamLoading, data: teamData } = useQuery(getTeamById, {
    skip: !teamId,
    variables: { teamId },
  });

  const onAddTeamClick = () => (canCreateTeam ? setShowAddNewTeamModal(true) : setSubscriptionRequiredModal(true));

  const loading = isAuthLoading || isPartyIdsLoading || isTeamLoading;

  const [menuOpen, setMenuOpen] = useState(false);

  const forceOpenRef = useRef(false);

  const userTeams = user.allTeams;

  useEffect(() => {
    const userInfo = {
      id: user.uuid,
      email: user.email,
      name: user.fullName,
    };

    const accountInfo = {
      id: teamData?.teamById?.uuid,
      name: teamData?.teamById?.name,
    };

    window?.totango?.identify(accountInfo, userInfo);
  }, [teamData, user.email, user.fullName, user.name, user.uuid]);

  const userLocationToRedirect = () => {
    const currentPath = window.location.pathname;

    const pathToRedirect = currentPath.includes('deals')
      ? paths.deals.landing
      : currentPath.includes('templates')
      ? paths.templates.landing
      : currentPath.includes('account')
      ? paths.account
      : currentPath.includes('organizations')
      ? paths.settings.organizations
      : currentPath.includes('requests')
      ? paths.settings.requests
      : paths.team;

    return pathToRedirect;
  };

  const handleOrgChange = (id: string) => {
    set(user.id, id);
    setSelectedWorkspaceId(id);
    refetchUserRestrictions();
  };

  const handleDropdownToggle = (show: boolean) => {
    if (forceOpenRef.current) {
      setMenuOpen(true);
      forceOpenRef.current = false;
      return;
    }

    setMenuOpen(show);
  };

  return (
    <>
      <Dropdown alignRight show={menuOpen} onToggle={handleDropdownToggle}>
        <Dropdown.Toggle
          id="team-selector"
          data-testid="team-selector"
          as={DropdownToggle}
          className="no-outline d-inline-flex ml-3 text-left"
          contentClassName="d-flex align-items-center"
          // @ts-ignore
          style={{
            top: 20,
            right: 20,
          }}
        >
          <span className={styles.workspacesItem}>
            <WorkspacesIcon height="30" />
          </span>
          {!loading && partyIds != null && (
            <div style={{ lineHeight: 1, maxWidth: '200px' }}>
              <div className="small text-muted">Workspace</div>
              <div
                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                className="medium text-muted font-weight-bold pt-1"
              >
                {teamData?.teamById.name}
              </div>
            </div>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {teamData && (
            <>
              {teamData && (
                <>
                  {userTeams.map(({ id, name }: { id: string; name: string }) => (
                    <Dropdown.Item
                      key={id}
                      onClick={() => handleOrgChange(id)}
                      as={!window.location.pathname.includes('account') ? A : undefined}
                      href={userLocationToRedirect()}
                      hrefParams={{ teamId: id }}
                      className={
                        styles[
                          `${
                            id === teamId || id === selectedWorkspaceId ? 'workspaceOption__active' : 'workspaceOption'
                          }`
                        ]
                      }
                    >
                      {name}
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Item
                    key="addNEwTeam.button"
                    className={classNames(styles.workspaceOption__newWorkspace)}
                    onClick={onAddTeamClick}
                  >
                    + New Workspace
                  </Dropdown.Item>
                </>
              )}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <CreateNewTeamModal show={showAddNewTeamModal} handleHide={() => setShowAddNewTeamModal(false)} />
      <SubscriptionRequiredModal
        isOpen={showSubscriptionRequiredModal}
        onClose={() => setSubscriptionRequiredModal(false)}
      />
    </>
  );
};

export default CurrentWorkspaceIndicator;
