import React, { ChangeEvent, useEffect, useState } from 'react';
import styles from '../../../lease-wizard/lease-document-wizard-header/components/headerActions/sendToPreparingToSignInternal/styles.module.scss';
import { FormattedMessage } from 'react-intl';
import { LeaseDocumentWizardModal } from '../../../lease-wizard/lease-document-wizard-modal/LeaseDocumentWizardModal';
import { LeaseDocumentWizardCTAType } from '../../../lease-wizard/lease-document-wizard-cta-stack/LeaseDocumentWizardCTAStack';
import bsCustomFileInput from 'bs-custom-file-input';
import { Form, Row } from 'react-bootstrap';
import { UploadFileData } from './FileUploadModalContainer';
import { SupportedDocFileTypes, SupportedFileTypes, SupportedTemplateFileTypes } from './constants';
import { usePrevious } from '../../../../utils/use-previous';
import { getSupportedExtensionsString } from './utils';
import { useSelector } from 'react-redux';
import { selectActiveModal } from '../../../../store/modals/modals.selectors';
import { ModalActionsEnum } from '../../../document-table/DocumentTableConstants';
import { AddNewTemplateRestrictedEditing } from '../../../../consts/forms-constants';
import Tooltip from '../../../tooltip';
import Toggle from '../../../toggle';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: ({ selectedFile, documentTitle, isRestrictedEditingEnabled }: UploadFileData) => void;
  isLoading: boolean;
  templateUpload?: boolean;
}

const FileUploadModal = ({ isOpen, onClose, onConfirm, isLoading, templateUpload }: Props) => {
  const [selectedFile, setSelectedFile] = useState<null | {
    contentType: string;
    fileData: ArrayBuffer | string | null;
    extension: string;
  }>(null);
  const [documentTitle, setDocumentTitle] = useState<string>('');
  const [isRestrictedEditingEnabled, setIsRestrictedEditingEnabled] = useState(false);
  const [errors, setErrors] = useState<{ title?: boolean; selectedFile?: string | boolean }>({});
  const activeModal = useSelector(selectActiveModal);
  const isDocxUploadModal = activeModal === ModalActionsEnum.UPLOAD_DOC_FILE;
  const isPreviousOpen = usePrevious(isOpen);
  const isValidExtension = selectedFile
    ? Object.values(
        isDocxUploadModal ? SupportedDocFileTypes : templateUpload ? SupportedTemplateFileTypes : SupportedFileTypes
      ).includes(selectedFile.extension.toLowerCase())
    : null;
  // https://react-bootstrap-v4.netlify.app/components/forms/#forms-custom-file
  useEffect(() => {
    if (!isOpen) return;

    bsCustomFileInput.init('#file-upload-custom');
  }, [isOpen]);

  const handleRestrictedEditingChange = () => {
    setIsRestrictedEditingEnabled(!isRestrictedEditingEnabled);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setErrors({
      title: !documentTitle.trim(),
      selectedFile: selectedFile ? (isValidExtension ? '' : getSupportedExtensionsString(templateUpload)) : true,
    });

    if (!selectedFile || !documentTitle || !isValidExtension) return;

    onConfirm({ selectedFile, documentTitle, isRestrictedEditingEnabled });
  };

  useEffect(() => {
    if (isPreviousOpen && !isOpen) {
      setSelectedFile(null);
      setErrors({});
      setDocumentTitle('');
    }
  }, [isOpen, isPreviousOpen]);

  const handleSelectedFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target || !e.target.files || !e.target.files[0]) return;
    const fileType = e.target.files[0].type;
    const fileNameParts = e.target.files[0].name.split('.');
    const fileExtension = fileNameParts[fileNameParts.length - 1];

    const reader = new FileReader();
    // @ts-ignore
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      // @ts-ignore
      setSelectedFile({
        fileData: reader.result,
        // @ts-ignore
        contentType: fileType,
        extension: fileExtension,
      });
    };
    reader.onerror = error => {
      console.warn('File reading error: ', error);
    };
  };

  const modalCTAs: LeaseDocumentWizardCTAType[] = [
    {
      onClick: onClose,
      label: 'Cancel',
      theme: 'link-secondary',
    },
    {
      onClick: handleSubmit,
      label: templateUpload ? 'Create Template' : isDocxUploadModal ? 'Import' : 'Upload',
      theme: 'secondary',
      isLoading,
      disabled: isLoading,
      className: styles['cancel-button'],
    },
  ];

  return (
    <LeaseDocumentWizardModal
      className={styles.wrapper}
      titleClassName={templateUpload ? 'upload-modal-title' : styles.title}
      size={'lg'}
      show={isOpen}
      onHide={onClose}
      title={
        <FormattedMessage
          id="lease-document-wizard-modal.send-for-signing"
          defaultMessage="{modalTitle}"
          values={{
            modalTitle: isDocxUploadModal ? 'Import from Word' : 'Upload PDF',
          }}
        />
      }
      modalCTAs={modalCTAs}
      templateUpload={templateUpload}
    >
      <Form className={styles.wrapper} noValidate onSubmit={handleSubmit}>
        <Row className="m-0">
          <Form.Group className="w-100">
            <Form.Label>
              <span className={templateUpload ? styles.uploadModalLabel : ''}>
                {templateUpload ? 'Template' : 'Document'} Name
              </span>
            </Form.Label>
            <Form.Control
              type="text"
              onChange={(e: any) => setDocumentTitle(e.target.value)}
              value={documentTitle}
              isInvalid={errors.title}
            />
          </Form.Group>
        </Row>
        <Row className="m-0">
          <Form.Group className="w-100">
            <Form.File
              id="file-upload-custom"
              label="Select File"
              custom
              accept={
                isDocxUploadModal
                  ? '.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  : '.pdf'
              }
              onChange={handleSelectedFileChange}
              isInvalid={!!errors.selectedFile}
              feedback={typeof errors.selectedFile === 'string' ? errors.selectedFile : null}
            />
          </Form.Group>
        </Row>
        {isDocxUploadModal && templateUpload && (
          <Row className="m-0">
            <div className={styles.restrictedEditingField}>
              <span className={styles.restrictedEditingField__label}>
                <FormattedMessage
                  id={AddNewTemplateRestrictedEditing.id}
                  defaultMessage={AddNewTemplateRestrictedEditing.defaultMessage}
                />
                <span className={styles.restrictedEditingField__tooltip}>
                  <Tooltip testId="add-form-restricted-editing-tooltip">
                    {AddNewTemplateRestrictedEditing.TOOLTIP_MESSAGE}
                  </Tooltip>
                </span>
              </span>
              <Toggle
                checked={isRestrictedEditingEnabled}
                isCustomDisabledState={false}
                onChange={handleRestrictedEditingChange}
              />
            </div>
          </Row>
        )}
      </Form>
    </LeaseDocumentWizardModal>
  );
};

export default FileUploadModal;
