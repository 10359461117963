import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import Alert from 'react-bootstrap/Alert';
import { fillPlaceholders, useNavigate, paths } from '../routing';
import ErrorMessage from '../components/error-message';
import { TextField } from '../components/fields';
import SubmitButton from '../components/submit-button';
import styles from '../pages/deals/lease-template-modal/styles.module.scss';
import { createDocument } from '../utils/api/create-document-from-lease-document-template/create-document-from-lease-document-template';
import { useApolloClient } from '@apollo/client';
import { useNotifications } from '../notifications';
import { dispatchLeaseDocumentResponseToStore } from '../utils/lease-document-wizard-utils/dispatch-lease-document-response-to-store/dispatch-lease-document-response-to-store';
import { useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import { TitleFormattedMessage } from '../components/titleFormattedMessage';
import { useTotango } from '../shared/hooks/useTotango';
import { TOTANGO_EVENT_TYPES } from '../utils/totango';

interface TemplateInputFormProps {
  errors: any;
  values: any;
  isSubmitting: boolean;
  isValid: boolean;
  onClose: () => void;
}

const CreateDocumentInputForm = ({ errors, values, isSubmitting, isValid, onClose }: TemplateInputFormProps) => (
  <Form noValidate>
    {errors._general && (
      <Alert variant="danger">
        <ErrorMessage>{errors._general}</ErrorMessage>
      </Alert>
    )}
    <div className={styles.createDocumentInputForm}>
      <TextField
        //@ts-ignore TODO update TextField to ts
        id="TemplateInputForm.title"
        name="title"
        type="title"
        label={false}
      />
    </div>

    <div className={styles.createDocumentButtonsGroup}>
      <Button variant="link" className="text-secondary" onClick={onClose}>
        <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
      </Button>

      <SubmitButton
        label={<FormattedMessage id="cta.create-document" defaultMessage="Create Document" />}
        disabled={isSubmitting || !isValid}
        isSubmitting={isSubmitting}
        data-testid="create-document-button"
      />
    </div>
  </Form>
);

const schema = yup.object().shape({
  title: yup.string().required(),
});

const CreateDocumentInputFormik = ({ dealId, templateId = null, onClose, isCreatingLeaseDraft, teamId }: any) => {
  const apolloClient = useApolloClient();
  const [, { error: notifyError }] = useNotifications();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sendTotango = useTotango();

  const onSubmit = async (values: any) => {
    isCreatingLeaseDraft();
    createDocument({ dealId, templateId, title: values.title })(apolloClient)
      .then(response => {
        const navigateUrl = fillPlaceholders(paths.documentPath, { dealId, teamId, documentId: response.id });
        //@ts-ignore
        dispatchLeaseDocumentResponseToStore(dispatch, response);
        sendTotango(TOTANGO_EVENT_TYPES.blankDocumentCreated);
        navigate(navigateUrl);
      })
      .catch(error => {
        notifyError({
          message: formatMessage({
            id: 'lease-document-wizard.create-offer.error',
            defaultMessage: 'Unable to create a Document',
          }),
        });
      })
      .finally(() => {
        onClose();
      });
  };
  return (
    <Formik validationSchema={schema} onSubmit={onSubmit} initialValues={{ title: '' }}>
      {formikProps => <CreateDocumentInputForm {...formikProps} onClose={onClose} />}
    </Formik>
  );
};

export default CreateDocumentInputFormik;
