import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import { useApolloClient, useMutation } from '@apollo/client';

import Button from 'react-bootstrap/Button';
import PropdocsModal from '../components/modal/PropdocsModal';
import Alert from 'react-bootstrap/Alert';

import { queries, mutations, usePartyIds } from '../api';
import { RegisterData, useAuth } from '../auth';
import { useNotifications } from '../notifications';
import { paths, useNavigate } from '../routing';

import ErrorMessage from '../components/error-message';
import PasswordStrength from '../components/password-strength';
import { TextField } from '../components/fields';
import { CheckboxField } from '../components/checkbox-or-radio-field';
import SubmitButton from '../components/submit-button';
import { Body as TermsBody } from '../components/terms-and-conditions';

import getPasswordStrength from '../utils/getPasswordStrength';
import { api as apiErrorMessages } from '../error-messages';
import LoadingSpinner from '../components/loading-spinner';
import { SuccessMessages } from '../success-messages';
import { isDeviceMobile } from '../shared/utils';
import { TitleFormattedMessage } from '../components/titleFormattedMessage';
import sendTotangoEvent, { TOTANGO_EVENT_TYPES } from '../utils/totango';
import { getTeamById } from '../utils/api/get-team-by-id';
import { getCurrentUser } from '../utils/api/get-current-user';
import { useTotango } from '../shared/hooks/useTotango';
import styles from './styles.module.scss'

interface RegisterFormProps {
  errors: { [key: string]: string };
  values: { [key: string]: string };
  isSubmitting?: boolean;
  isValid?: boolean;
  setFieldValue: (fieldName: string, fieldValue: string | boolean) => void;
  invitationToken?: string;
  confirmationToken?: string;
  invitation?: any;
  isNewCounterparty?: boolean;
}

const RegisterForm = ({
  errors,
  values,
  isSubmitting,
  isValid,
  setFieldValue,
  invitationToken,
  confirmationToken,
  invitation,
  isNewCounterparty,
}: RegisterFormProps) => {
  // Update password score readout on password change
  const [score, setScore] = useState<number | null>(null);
  const updateScore = useCallback(
    async password => {
      setScore(await getPasswordStrength(password));
    },
    [setScore]
  );
  useEffect(() => {
    updateScore(values.password);
  }, [updateScore, values.password]);

  // When company name changes, update team name too if they were identical
  const prevCompanyNameRef = useRef(values.companyName);
  useEffect(() => {
    if (prevCompanyNameRef.current === values.teamName) {
      setFieldValue('teamName', values.companyName);
    }
    prevCompanyNameRef.current = values.companyName;
  }, [setFieldValue, values.companyName, values.teamName]);

  // Control the terms&conditions modal
  const [showTerms, setShowTerms] = useState(false);

  return (
    <Form noValidate>
      {errors._general && (
        <Alert variant="danger">
          <ErrorMessage>{errors._general}</ErrorMessage>
        </Alert>
      )}

      {invitation ? (
        <Field type="hidden" name="invitationToken" />
      ) : confirmationToken ? (
        <Field type="hidden" name="confirmationToken" />
      ) : (
        <TextField
          id="registerForm.email"
          name="email"
          type="email"
          label={<FormattedMessage id="form.email.label" defaultMessage="Email" />}
          autoComplete="username"
        />
      )}

      <TextField
        id="registerForm.fullName"
        name="fullName"
        type="text"
        label={<FormattedMessage id="form.full-name.label" defaultMessage="Full name" />}
        autoComplete="name"
      />

      {(isNewCounterparty || !invitationToken) && (
        <TextField
          id="registerForm.companyName"
          name="companyName"
          type="text"
          label={<FormattedMessage id="form.company-name.label" defaultMessage="Company name" />}
          autoComplete="organization"
        />
      )}

      <Field id="registerForm.teamName" name="teamName" type="hidden" />

      <TextField
        id="registerForm.password"
        name="password"
        type="password"
        label={<FormattedMessage id="form.password.label" defaultMessage="Password" />}
        autoComplete="new-password"
        afterField={<PasswordStrength score={score} />}
      />

      <CheckboxField
        id="registerForm.acceptTerms"
        name="acceptTerms"
        className="my-4 body-text"
        label={
          <FormattedMessage
            id="form.accept-terms.label"
            defaultMessage="I have read and accept the Propdocs <a>Terms &amp; Conditions</a>"
            values={{
              a: (msg: string) => (
                <Button variant="link" className="p-0 align-baseline" onClick={() => setShowTerms(true)}>
                  {msg}
                </Button>
              ),
            }}
          />
        }
      />

      <div className="text-right">
        <SubmitButton
          label={
            <FormattedMessage id="cta.register" defaultMessage={confirmationToken ? 'Create Account' : 'Continue'} />
          }
          disabled={isSubmitting || !isValid}
          isSubmitting={isSubmitting}
          data-testid="form-submit-button"
        />
      </div>

      <PropdocsModal show={showTerms} setShow={() => setShowTerms(false)} size="lg" title={<FormattedMessage id="title.terms-and-conditions" defaultMessage="Terms &amp; Conditions" />} >
        <div>
          <div className={styles.signupModal__body}>
            <TermsBody />
          </div>
          <div className={styles.signupModal__actions}>
            <Button variant="link" className="text-secondary" onClick={() => setShowTerms(false)}>
              <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setShowTerms(false);
                setFieldValue('acceptTerms', true);
              }}
            >
              <FormattedMessage id="cta.accept" defaultMessage="Accept" />
            </Button>
          </div>
        </div>
      </PropdocsModal>
    </Form>
  );
};

const baseSchemaShape = {
  password: yup
    .string()
    .required()
    .min(8)
    // @ts-ignore -- custom yup validator
    .minPasswordStrength(3),
  fullName: yup.string().required(),
  // @ts-ignore -- custom yup validator
  acceptTerms: yup.boolean().checked({
    id: 'phrase.terms-must-be-accepted',
    defaultMessage: 'Accept terms and conditions before completing sign up',
  }),
};

const schema = yup.object().shape({
  ...baseSchemaShape,
  email: yup.string().email(),
  companyName: yup.string().required(),
  teamName: yup.string().required(),
});
const invitationSchema = yup.object().shape({
  ...baseSchemaShape,
  invitationToken: yup.string().required(),
});

interface RegisterFormikProps {
  invitationToken?: string;
  confirmationToken?: string;
  invitation?: any;
  isNewCounterparty?: boolean;
}

const RegisterFormik = ({ invitationToken, confirmationToken, invitation, isNewCounterparty }: RegisterFormikProps) => {
  const [, { register, registerWithEmailVerified }] = useAuth();
  const navigate = useNavigate();
  const [, { success: notifySuccess, warning: notifyWarning }] = useNotifications();
  const { loading: partyIdsLoading, partyIds } = usePartyIds();
  const [createTeam] = useMutation(mutations.createTeam);
  const apolloClient = useApolloClient();
  const sendTotango = useTotango();

  useEffect(() => {
    confirmationToken &&
      notifySuccess({
        message: SuccessMessages.verification,
      });
  }, [confirmationToken, notifySuccess]);

  const onSubmit = async (values: RegisterData, actions: any) => {
    const { success, errors } = confirmationToken
      ? await registerWithEmailVerified(values, confirmationToken)
      : await register(values);

    if (success) {
      if (!invitation) {
        await createTeam({
          variables: {
            name: values.teamName!,
            partyId: partyIds.landlord,
          },
          update: (store, { data }) => {
            const team = data!.createTeam;
            // Add this new team to the current user's list of teams
            const cachedData: { [key: string]: any } = store.readQuery({ query: queries.getCurrentUser }) || {};
            const updatedCachedData = {
              ...cachedData,
              currentUser: { ...cachedData.currentUser, allTeams: [...cachedData.currentUser.allTeams, team] },
            };

            store.writeQuery({ query: queries.getCurrentUser, data: updatedCachedData });
          },
        });
      }

      navigate(paths.home);

      if (invitation) {
        notifySuccess({
          message: SuccessMessages.registration,
        });
      } else {
        notifySuccess({
          message: SuccessMessages.registrationCheckEmail,
        });
      }
    } else {
      if (errors.id === apiErrorMessages.existingUser.id) {
        // Duplicate email address; notify user and navigate to login page
        navigate(paths.auth.login);
        notifyWarning({
          message: {
            id: 'phrase.register.email-exists-login',
            defaultMessage:
              'This email address is already associated with an account. Log in, or reset your password if you forgot it.',
          },
        });
      } else {
        actions.setErrors(errors);
      }
    }
  };

  if (partyIdsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Formik
      validationSchema={invitation ? invitationSchema : schema}
      onSubmit={onSubmit}
      initialValues={{
        email: '',
        password: '',
        fullName: '',
        companyName: '',
        teamName: '',
        acceptTerms: false,
        // @ts-ignore
        invitationToken,
        confirmationToken,
      }}
    >
      {formikProps => (
        // @ts-ignore
        <RegisterForm
          invitationToken={invitationToken}
          confirmationToken={confirmationToken}
          invitation={invitation}
          isNewCounterparty={isNewCounterparty}
          {...formikProps}
        />
      )}
    </Formik>
  );
};

export default RegisterFormik;
