import React, { ReactNode, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useMutation } from '@apollo/client';
import { queries, mutations } from '../api';
import { useNotifications } from '../notifications';
import { useTeamInfo } from '../team-id-context';
import LoadingSpinner from '../components/loading-spinner';
import { CreateDealErrorMessage } from '../consts/forms-constants';
import { fillPlaceholders, paths } from '../routing';
import AddNewDealForm from './AddNewDealForm';
import { useTotango } from '../shared/hooks/useTotango';
import { TOTANGO_EVENT_TYPES } from '../utils/totango';


interface AddNewDealFormVariables {
  title: string;
}

interface AddNewDealFormProps {
  bodyContainer: any;
  actionsContainer: any;
  renderSecondaryAction: () => ReactNode;
}

const schema = yup.object().shape({
  teamId: yup.string().required(),
  deal_name: yup
    .string()
    .trim()
    .required(),
});

const AddNewDealFormik = ({ bodyContainer, actionsContainer, renderSecondaryAction }: AddNewDealFormProps) => {
  const navigate = useNavigate();
  const { loading: teamInfoLoading, teamId, teamName } = useTeamInfo();
  const [, { error: notifyError }] = useNotifications();
  const sendTotango = useTotango();

  const [createDeal] = useMutation(mutations.createDeal, {
    refetchQueries: [
      {
        query: queries.getDealsForTeam,
        variables: {
          teamId
        },
      },
    ],
  });

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        const variables: AddNewDealFormVariables = {
          title: values.deal_name,
        };

        const createdDealData = await createDeal({
          variables: { teamId: values.teamId, ...variables },
        });

        const dealId = createdDealData!.data!.createDeal!.id;
        const dealDeatilsUrl = fillPlaceholders(paths.deals.detail, {
          teamId,
          dealId,
        });

        sendTotango(TOTANGO_EVENT_TYPES.dealCreated);

        navigate(dealDeatilsUrl);
      } catch (error) {
        console.log(error);
        notifyError({ message: CreateDealErrorMessage.CREATE_DEAL });
      }
    },
    [createDeal, navigate, notifyError, teamId, sendTotango]
  );

  if (teamInfoLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        teamId,
        deal_name: '',
      }}
    >
      {formikProps => (
        <AddNewDealForm
          {...formikProps}
          bodyContainer={bodyContainer}
          actionsContainer={actionsContainer}
          renderSecondaryAction={renderSecondaryAction}
        />
      )}
    </Formik>
  );
};

export default AddNewDealFormik;
