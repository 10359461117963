import React, { ChangeEvent, useCallback, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApolloClient, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import pull from 'lodash/pull';
import { Col, Dropdown, Row, Button } from 'react-bootstrap';
import { useAuth } from '../../../../../../auth';
import RequestInternalApprovalModal from './RequestInternalApprovalModal';
import PillToggle from '../../../../../toggle';
import TeamMembersDropdown from './TeamMembersDropdown';
import {
  RequestApproval,
  requestApprovalSuccessMessage,
  requestReviewSuccessMessage,
  RequestReviewThresholdValues,
  SetApprovalRequirement,
} from './RequestInternalApprovalModalConstants';
import Toggle from '../../../../../../forms/components/Toggle';
import { createDocumentApprovalRequest } from '../../../../../../utils/api/request-review-mutations/createDocumentApprovalRequest';
import { selectDealId } from '../../../../../../store/deals/deals.selectors';
import { fillPlaceholders, useNavigate, paths } from '../../../../../../routing';
import { selectDocumentSource } from '../../../../../../store/lease-document/selectors/lease-document.selectors';
import styles from './RequestReview.module.scss';
import { useNotifications } from '../../../../../../notifications';
import { DocumentSourceEnum } from '../../../../lease-editor/constants/EditorConstats';
import { saveDocumentVersionOnPossessionPass } from '../../../lease-document-wizard-header.utils';
import { useDocumentRole } from '../../../../../../shared/hooks/useDocumentRole/useDocumentRole';
import { mutations } from '../../../../../../api';
import { Warning } from '../../../../../shared/warning';
import { createDocumentViewRequest } from '../../../../../../utils/api/request-review-mutations/createDocumentViewRequest';
import { useTotango } from '../../../../../../shared/hooks/useTotango';
import { TOTANGO_EVENT_TYPES } from '../../../../../../utils/totango';
import PlaceholderWarningModal from '../../../../lease-document-wizard-modal/custom-modals/placeholderWarningModal/placeholderWarningModal';

type CreateDocumentModalComponentProps = {
  isOpen: boolean;
  documentId: string;
  teamId: string;
  onClose: () => void;
  dealMembers: any[];
  teamMembersWithApprovePermission: any[];
  handleSaveDocument?: (() => Promise<void>) | undefined;
  isReviewOnly: boolean;
  placeholdersToComplete?: number;
};

const RequestInternalApprovalModalContainer = ({
  isOpen,
  onClose,
  dealMembers,
  teamMembersWithApprovePermission = [],
  documentId,
  handleSaveDocument,
  teamId,
  isReviewOnly,
  placeholdersToComplete = 0,
}: CreateDocumentModalComponentProps) => {
  const dealId = useSelector(selectDealId);
  const documentSource = useSelector(selectDocumentSource);
  const apolloClient = useApolloClient();
  const [{ user }] = useAuth();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { possessionTeamName } = useDocumentRole(documentId);
  const [, { success: notifySuccess, error: notifyError }] = useNotifications();
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<string[]>([]);
  const [isFullApprovementRequired, setIsFullApprovementRequired] = useState(false);
  const [isRequestApproval, setIsRequestApproval] = useState(false);
  const [unlockDocument] = useMutation(mutations.unlockDocument);
  const sendTotango = useTotango();
  const dispatch = useDispatch();

  const onApprove = (isRequestApproval: boolean) => {
    if (teamId) {
      const dealDeatilsUrl = fillPlaceholders(paths.deals.detail, {
        teamId,
        dealId,
      });
      unlockDocument({ variables: { documentId, userId: user.id, sessionActivities: [] } });
      if (isRequestApproval) {
        sendTotango(TOTANGO_EVENT_TYPES.docSentForInternalApproval);
      }
      navigate(dealDeatilsUrl);
      notifySuccess({
        message: isRequestApproval
          ? formatMessage(requestApprovalSuccessMessage)
          : formatMessage(requestReviewSuccessMessage),
      });
    }
  };

  const onSubmit = useCallback(async () => {
    const isAppDocument = documentSource === DocumentSourceEnum.APP;

    if (isAppDocument) {
      handleSaveDocument && (await handleSaveDocument());
      await saveDocumentVersionOnPossessionPass({ success: true, possessionTeamName });
    }

    if (isRequestApproval) {
      const variables = {
        dealId,
        documentId,
        teamId,
        threshold: isFullApprovementRequired ? RequestReviewThresholdValues.ALL : RequestReviewThresholdValues.ONE,
        userIds: selectedTeamMembers,
        confirm: true
      };

      createDocumentApprovalRequest(variables)(apolloClient)
        .then(async data => {
          onApprove(isRequestApproval);
        })
        .catch(error =>
          notifyError({
            message: error,
          })
        );
    } else {
      const variables = {
        dealId,
        documentId,
        viewerIds: selectedTeamMembers,
      };

      createDocumentViewRequest(variables)(apolloClient)
        .then(async data => {
          onApprove(isRequestApproval);
        })
        .catch(error =>
          notifyError({
            message: error,
          })
        );
    }
  }, [
    isRequestApproval,
    isFullApprovementRequired,
    unlockDocument,
    selectedTeamMembers,
    dealId,
    documentId,
    teamId,
    apolloClient,
    notifyError,
    notifySuccess,
    formatMessage,
    documentSource,
    handleSaveDocument,
    possessionTeamName,
    user,
    navigate,
  ]);

  const handleSend = useCallback(async () => {
    const documentPendingActionsPlugin = window.editor?.plugins?.get('PendingActions');

    if (!documentPendingActionsPlugin) {
      return onSubmit();
    }

    if (!documentPendingActionsPlugin.hasAny) {
      return onSubmit();
    }

    documentPendingActionsPlugin.on('change:hasAny', (event: any) => {
      if (!documentPendingActionsPlugin.hasAny) {
        event.off();
        event.stop();
        return onSubmit();
      }
    });
  }, [onSubmit]);

  const teamMembersIds = teamMembersWithApprovePermission?.map(member => {
    return member?.id;
  });
  const dealMembersIds = dealMembers.filter(({ id }) => teamMembersIds?.includes(id)).map(({ id }) => id);

  const teamMembersWithApprovePermissionAndAccessToDeal = teamMembersWithApprovePermission.filter(
    (member: { id: string }) => dealMembersIds.includes(member.id) && member.id !== user.id
  );

  const navigateToManageTeamPage = () => {
    const url = fillPlaceholders(paths.team, { teamId });
    navigate(url);
  };

  const noActiveApproversMessage = () => {
    return (
      <span>
        There are no team members with access to this deal.{' '}
        <button className={styles.manageDealTeamLink} onClick={navigateToManageTeamPage}>
          Manage your team
        </button>{' '}
        to update existing access or invite new team members.
      </span>
    );
  };

  const onModalClose = () => {
    onClose();
  };

  const modalFilters = () => {
    return (
      <div>
        {!teamMembersWithApprovePermissionAndAccessToDeal.length && (
          <Warning title="No Team Members" text={noActiveApproversMessage()} />
        )}
        <div className="mb-3">
          {isReviewOnly ? (
            <FormattedMessage id="lease.template.creator" defaultMessage="Share for internal review." />
          ) : (
            <FormattedMessage
              id="lease.template.creator"
              defaultMessage="Share for internal review and optionally request approval."
            />
          )}
        </div>
        <Dropdown className={styles.teamMembersDropdown__container}>
          <Toggle
            id={`dropdown-select-deals-t${teamId}-u${dealId}`}
            selectedCount={selectedTeamMembers.length}
            header={'Reviewer'}
          />

          <Dropdown.Menu
            className={styles.teamMembersDropdown}
            as={TeamMembersDropdown}
            dropdownId={`dropdown-select-teamMembers-t${teamId}-u${dealId}`}
            dealChecked={(user: { id: string }) => {
              return selectedTeamMembers?.includes(user.id);
            }}
            dealValue={(user: { id: string }) => user.id}
            onDealChange={async (event: ChangeEvent<HTMLInputElement>) => {
              const checkbox = event.currentTarget;
              const userId = event.currentTarget.value;

              if (checkbox.checked) {
                setSelectedTeamMembers([...selectedTeamMembers, userId]);
              } else {
                setSelectedTeamMembers([...pull(selectedTeamMembers, userId)]);
              }
            }}
          >
            {teamMembersWithApprovePermissionAndAccessToDeal}
          </Dropdown.Menu>
        </Dropdown>
        {!isReviewOnly && (
          <>
            <Row>
              <Col lg={6}>
                <div className={styles.setApprovalRequirementToggle}>
                  <span className={styles.setApprovalRequirementToggle__label}>
                    <FormattedMessage id={RequestApproval.id} defaultMessage={RequestApproval.defaultMessage} />
                  </span>
                  <PillToggle
                    checked={isRequestApproval}
                    isCustomDisabledState={false}
                    onChange={() => {
                      setIsRequestApproval(!isRequestApproval);
                      setIsFullApprovementRequired(false);
                    }}
                    disabled={!teamMembersWithApprovePermissionAndAccessToDeal.length}
                  />
                </div>
              </Col>
            </Row>
            {isRequestApproval && selectedTeamMembers.length > 1 && (
              <Row>
                <Col lg={6}>
                  <div className={styles.setApprovalRequirementToggle}>
                    <span className={styles.setApprovalRequirementToggle__label}>
                      <FormattedMessage
                        id={SetApprovalRequirement.id}
                        defaultMessage={SetApprovalRequirement.defaultMessage}
                      />
                    </span>
                    <PillToggle
                      checked={isFullApprovementRequired}
                      isCustomDisabledState={false}
                      onChange={() => setIsFullApprovementRequired(!isFullApprovementRequired)}
                      disabled={!teamMembersWithApprovePermissionAndAccessToDeal.length}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </div>
    );
  };

  const hasRequiredPlaceholders = placeholdersToComplete !== 0;

  const [showWarningModal, setShowWarningModal] = useState(isOpen && hasRequiredPlaceholders);

  const [showModal, setShowModal] = useState(isOpen && !hasRequiredPlaceholders);

  const handleWarningModalClose = () => {
    setShowWarningModal(false);
    setShowModal(false);
    onModalClose();
  }

  useEffect(() => {
    setShowWarningModal(isOpen && hasRequiredPlaceholders);

    setShowModal(isOpen && !hasRequiredPlaceholders);
  }, [isOpen]);

  return (
    <>
      <RequestInternalApprovalModal
        isOpen={showModal}
        onClose={onModalClose}
        modalFilters={modalFilters()}
        onSubmit={handleSend}
        submitDisabled={!selectedTeamMembers?.length}
      />
      <PlaceholderWarningModal
        isOpen={showWarningModal}
        placeholders={placeholdersToComplete}
        onContinue={() => {
          setShowWarningModal(false);
          setShowModal(true);
        }}
        onReview={handleWarningModalClose}
        onHide={handleWarningModalClose}
      />
    </>
  );
};

export default RequestInternalApprovalModalContainer;
