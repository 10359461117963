export const TOTANGO_EVENT_TYPES = {
  dealCreated: { name: 'Deal Created', module: 'Deals' },
  docSentViaEmail: { name: 'Doc Sent Via Email', module: 'Document' },
  docSentForSigning: { name: 'Doc Sent for Signing', module: 'Document' },
  dealStatusSetCompleted: { name: 'Deal Status set to Completed', module: 'Deal' },
  blankDocumentCreated: { name: 'Blank Document Created', module: 'Document' },
  documentPDFUploaded: { name: 'Document PDF Uploaded', module: 'Document' },
  docSentViaPropdocs: { name: 'Doc Sent Via Propdocs', module: 'Document' },
  documentImportedFromWord: { name: 'Document Imported from Word', module: 'Document' },
  documentCreatedFromTemplate: { name: 'Document Created from Template', module: 'Document' },
  organizationRequestApproved: { name: 'Organization Request Approved', module: 'Organizations' },
  organizationAccessRequest: { name: 'Organization Access Request', module: 'Organizations' },
  templatePDFCreated: { name: 'Template PDF Created', module: 'Template' },
  templateRestrictedDocCreated: { name: 'Template Restricted Doc Created', module: 'Template' },
  templateEditableDocCreated: { name: 'Template Editable Doc Created', module: 'Template' },
  teamMemberInvited: { name: 'Team Member Invited', module: 'Team' },
  clauseCreated: { name: 'Clause Created', module: 'Clause' },
  clauseInserted: { name: 'Clause Inserted', module: 'Clause' },
  docSentForInternalApproval: { name: 'Doc Sent for Internal Approval', module: 'Document' },
  docInternalApprovalRejected: { name: 'Doc Internal Approval Rejected', module: 'Document' },
  organizationTemplateUsed: { name: 'Organization Template Used', module: 'Template' },
};

export interface TotangoUtilProps {
  eventType: { name: string; module: string };
  user: any;
  adminUser: any;
  teamUUID: string;
  teamName: string;
  contact?: 'yes' | 'no';
}

export interface TotangoParams {
  account: {
    id: string;
    name: string;
    account_id?: string;
  };
  user: {
    id: string;
    name?: string;
    company_name?: string;
    email?: string;
    Contact?: string;
    Key_Contact?: string;
  };
}

const configureTotangoObject = ({ eventType, user, teamUUID, teamName }: TotangoUtilProps) => {
  switch (eventType.name) {
    case TOTANGO_EVENT_TYPES.organizationAccessRequest.name:
      return {
        account: {
          id: teamUUID,
          name: teamName,
        },
        user: {
          id: user.uuid,
          name: user.fullName,
        },
      };

    case TOTANGO_EVENT_TYPES.organizationRequestApproved.name:
    case TOTANGO_EVENT_TYPES.organizationTemplateUsed.name:
      return {
        account: {
          id: teamUUID,
          name: teamName,
        },
        user: {
          id: user?.uuid,
          Contact: 'no',
          name: user?.fullName,
        },
      };

    case TOTANGO_EVENT_TYPES.teamMemberInvited.name:
    case TOTANGO_EVENT_TYPES.dealCreated.name:
    case TOTANGO_EVENT_TYPES.dealStatusSetCompleted.name:
    case TOTANGO_EVENT_TYPES.blankDocumentCreated.name:
    case TOTANGO_EVENT_TYPES.documentCreatedFromTemplate.name:
    case TOTANGO_EVENT_TYPES.documentImportedFromWord.name:
    case TOTANGO_EVENT_TYPES.documentPDFUploaded.name:
    case TOTANGO_EVENT_TYPES.docSentViaPropdocs.name:
    case TOTANGO_EVENT_TYPES.docSentViaEmail.name:
    case TOTANGO_EVENT_TYPES.docSentForInternalApproval.name:
    case TOTANGO_EVENT_TYPES.docInternalApprovalRejected.name:
    case TOTANGO_EVENT_TYPES.docSentForSigning.name:
    case TOTANGO_EVENT_TYPES.templateEditableDocCreated.name:
    case TOTANGO_EVENT_TYPES.templateRestrictedDocCreated.name:
    case TOTANGO_EVENT_TYPES.templatePDFCreated.name:
    case TOTANGO_EVENT_TYPES.clauseCreated.name:
    case TOTANGO_EVENT_TYPES.clauseInserted.name:
      return {
        account: {
          id: teamUUID,
          name: teamName,
        },
        user: {
          id: user.uuid,
          name: user.fullName,
        },
      };

    default:
      return {
        account: {
          id: teamUUID,
          name: teamName,
        },
        user: {
          id: user.uuid,
          name: user.fullName,
        },
      };
  }
};

const hasNumericalID = (obj: TotangoParams): boolean => {
  const isNumeric = (str: string) => {
    return !isNaN(str as any) && !isNaN(parseFloat(str));
  };

  for (const [key, value] of Object.entries(obj.account)) {
    if (obj.account.hasOwnProperty(key)) {
      if (value && isNumeric(value)) {
        return true;
      }
    }
  }

  for (const [key, value] of Object.entries(obj.user)) {
    if (obj.user.hasOwnProperty(key)) {
      if (value && isNumeric(value)) {
        return true;
      }
    }
  }

  return false;
};

const sendTotangoEvent = ({ eventType, user, teamUUID, teamName, adminUser, contact = 'yes' }: TotangoUtilProps) => {
  const isEnabledTotango = process.env.REACT_APP_ENABLE_TOTANGO === 'true';

  if (!isEnabledTotango) {
    return;
  }

  const totango = configureTotangoObject({ eventType, user, teamUUID, teamName, adminUser, contact });

  if (!hasNumericalID(totango)) {
    window?.totango?.track(eventType.name, eventType.module, totango.account, {
      ...totango.user,
      env: !!process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT === 'production' ? 'prod' : 'test',
    });
  }
};

export default sendTotangoEvent;
