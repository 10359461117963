import React, { useState } from 'react';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { graphqlErrorsToUiErrors, mutations, queries, useTeamPermissions } from '../api';
import { useNotifications } from '../notifications';
import { useTeamInfo } from '../team-id-context';
import ErrorMessage from '../components/error-message';
import LoadingSpinner from '../components/loading-spinner';
import { AddNewTemplateFormikProps, DefaultDealsProps, PermissionProps, TemplateType } from '../consts/forms-types';
import AddNewTemplateForm from './AddNewTemplateForm';
import { DEFAULT_OPTION_ALL_TYPES, TemplateErrorMessage } from '../consts/forms-constants';
import { Permissions } from '../shared/constants/permissions';
import { paths } from '../routing';
import { useTotango } from '../shared/hooks/useTotango';
import { TOTANGO_EVENT_TYPES } from '../utils/totango';

interface CreateTemplateDataVariables {
  name: string;
  txType: string;
  templateType?: string;
}

const schema = yup.object().shape({
  teamId: yup.string().required(),
  template_name: yup
    .string()
    .trim()
    .required(),
  defaultPermissions: yup
    .array()
    .of(yup.string())
    .ensure()
    .required(),
  defaultDeals: yup.array().of(yup.string()),
  txType: yup.string().required(),
});

const AddNewTemplateFormik = ({
  bodyContainer,
  actionsContainer,
  renderSecondaryAction,
  onSuccess,
  existingTemplateFields,
  templateFormEditMode,
  templateType,
}: AddNewTemplateFormikProps) => {
  const navigate = useNavigate();
  const { loading: teamInfoLoading, teamId } = useTeamInfo();
  const { loading: permissionsLoading, teamPermissions } = useTeamPermissions();
  //@ts-ignore TODO useNotifications to ts
  const [, { error: notifyError }] = useNotifications();
  const [isRestrictedEditingEnabled, setIsRestrictedEditingEnabled] = useState(
    existingTemplateFields?.editorConfiguration?.restricted_editing
  );
  const [createDocumentTemplate] = useMutation(mutations.createDocumentTemplate);
  const [updateDocumentTemplate] = useMutation(mutations.updateDocumentTemplate);
  const sendTotango = useTotango();

  const { loading: dealsLoading, data: dealsData, error: dealsGraphqlError } = useQuery(queries.getDealsForTeam, {
    skip: !teamId,
    variables: {
      teamId,
    },
  });

  const isLoading = teamInfoLoading || permissionsLoading || dealsLoading;
  const defaultPermissions = [];
  const viewPermissionId = teamPermissions?.find(({ key }: PermissionProps) => key === Permissions.View)?.id;
  const whitelistPermissionId = teamPermissions?.find(({ key }: PermissionProps) => key === Permissions.Whitelist)?.id;

  const getDefaultDeals = () => {
    return dealsData?.dealsForTeam.map(({ id }: DefaultDealsProps) => id);
  };

  const onSubmit = async (values: any) => {
    try {
      const variables: CreateTemplateDataVariables = {
        name: values.template_name,
        txType: values.txType,
      };

      if (!templateFormEditMode) {
        const createdTemplateData = await createDocumentTemplate({
          variables: {
            teamId: values.teamId,
            templateType: templateType!,
            restrictedEditing: isRestrictedEditingEnabled,
            ...variables,
          },
        });
        const newTemplateId = createdTemplateData!.data!.createDocumentTemplate!.id;
        const dealUrl = paths.templates.document.replace(':teamId', teamId).replace(':templateId', newTemplateId);

        if (templateType === TemplateType.DOCUMENT) {
          sendTotango(
            isRestrictedEditingEnabled
              ? TOTANGO_EVENT_TYPES.templateRestrictedDocCreated
              : TOTANGO_EVENT_TYPES.templateEditableDocCreated
          );
        }
        if (templateType === TemplateType.CLAUSE) {
          sendTotango(TOTANGO_EVENT_TYPES.clauseCreated);
        }
        navigate(dealUrl);
      }

      if (templateFormEditMode && existingTemplateFields) {
        await updateDocumentTemplate({
          variables: { documentTemplateId: existingTemplateFields.id, ...variables },
        });
        onSuccess();
      }
    } catch (error) {
      console.log(error);
      notifyError({ message: TemplateErrorMessage.CREATE_TEMPLATE });
    }
  };

  if (viewPermissionId != null) defaultPermissions.push(viewPermissionId);
  if (whitelistPermissionId != null) defaultPermissions.push(whitelistPermissionId);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (!dealsData) {
    //@ts-ignore TODO graphqlErrorsToUiErrors to ts
    const errors: { _general: any } = graphqlErrorsToUiErrors(dealsGraphqlError);
    return <ErrorMessage>{errors._general}</ErrorMessage>;
  }

  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{
        teamId,
        template_name: existingTemplateFields ? existingTemplateFields.name : '',
        defaultPermissions,
        defaultDeals: getDefaultDeals(),
        txType: existingTemplateFields ? existingTemplateFields.txType : DEFAULT_OPTION_ALL_TYPES,
      }}
    >
      {formikProps => (
        <AddNewTemplateForm
          {...formikProps}
          bodyContainer={bodyContainer}
          actionsContainer={actionsContainer}
          renderSecondaryAction={renderSecondaryAction}
          templateFormEditMode={templateFormEditMode}
          isRestrictedEditingEnabled={isRestrictedEditingEnabled}
          handleRestrictedEditingChange={() => setIsRestrictedEditingEnabled(!isRestrictedEditingEnabled)}
          templateType={templateType || existingTemplateFields?.templateType}
          templateSource={existingTemplateFields?.source}
        />
      )}
    </Formik>
  );
};

export default AddNewTemplateFormik;
