import { DocumentPermissions } from '../../pages/lease-document-wizard/shared/hooks/use-lease-document-team-permissions/use-lease-document-team-permissions';
import { DealModalActionsEnum } from '../../pages/deals/constants/DealConstants';
import { DocumentSourceEnum } from '../lease-wizard/lease-editor/constants/EditorConstats';
import { ImportedDocumentSupportedContentTypes } from '../shared/modals/fileUploadModal/types';
import { DocumentApprovalRequestType } from '../lease-wizard/lease-document.types';
import { DocumentSigner } from '../../store/lease-document/actions/set-lease-document-signers.action';
import { Document, DocumentSuggestion } from '../../gql/graphql';

export enum DocumentTableEnum {
  CARD_ID = 'document.table.title',
  DEFAULT_MESSAGE = 'Documents',
  NO_DOCUMENTS_MESSAGE = 'No documents created for this Deal',
  BOOTSTRAP_WIDTH_COLUMNS = '12',
}

export interface DocumentInterface {
  id?: any;
  receiverTeamAssignedAt: string;
  eversignPdfUrl: string;
  getDocumentById?: any;
  currentTeamPossession: boolean;
  shouldReturnForReceiverReview: boolean;
  documentSuggestions: Array<DocumentSuggestion>;
  status: string;
  source: DocumentSourceEnum;
  importedAssetUrl: string | null;
  isEligibleForSigningPrep?: boolean;
  generatedDocumentPdfUrl: string | null;
  importedAssetContentType: ImportedDocumentSupportedContentTypes | null;
  approvalRequest: DocumentApprovalRequestType;
  isEditable: boolean;
  title: string;
  signers: DocumentSigner[];
  receiverTeam: { id: string } | null;
}

export enum ModalActionsEnum {
  VIEW = 'lease_document_action_modal_view',
  SEND = 'lease_document_action_modal_send',
  SEND_VIA_EMAIL = 'lease_document_action_modal_send_via_email',
  SEND_EXECUTED = 'lease_document_action_modal_send-executed',
  UNSEND = 'lease_document_action_modal_unsend',
  TERMINATE = 'lease_document_action_modal_terminate',
  DOWNLOAD = 'lease_document_action_modal_download',
  SEND_FOR_SIGNING = 'lease_document_action_modal_send_for_signing',
  INTERNAL_SEND_FOR_SIGNING = 'lease_document_action_modal_internal_send_for_signing-internal',
  UPLOAD_FILE = 'lease_document_action_modal_upload-file',
  UPLOAD_DOC_FILE = 'lease_document_action_modal_upload-doc-file',
  GRANT_ACCESS = 'uploaded_action_modal_grat-access',
  UNLOCK_DOCUMENT = 'uploaded_action_modal_unlock-document',
  INTERNAL_APPROVAL = 'document_action_modal_send_for_internal_approval',
  SEND_REMINDER = 'lease_document_action_modal_send_reminder',
  CANCEL_SIGNING = 'lease_document_action_modal_cancel_signing',
}

export enum CreateDocumentModalsEnum {
  TEMPLATE_SELECTOR = 'lease_document_selector_modal',
  BLANK_DOCUMENT = 'create_blank_document',
}

export enum ModalActionTitle {
  TERMINATE = 'Terminate Document',
  SEND = 'Request Counterparty Review',
  UNSEND = 'Unsend Document',
  SEND_FOR_SIGNING = 'Ready to Sign',
  SEND_FOR_SIGNING_INTERNAL = 'Prepare Document for Signing',
  GRANT_ACCESS = 'Grant Counterparty Access',
  UNLOCK_DOCUMENT = 'Unlock Document',
  CANCEL_SIGNING = 'Cancel Signing',
}

export enum ModalActionDescription {
  SEND = 'Your team will not be permitted to edit the document until the counterparty sends it back.',
  SEND_FOR_SIGNING = 'The document creator will be notified that you have indicated the document is ready to sign. Once prepared, the document will be sent to the signers.',
  GRANT_ACCESS = 'Both teams will be able to access the document.',
  UNLOCK_DOCUMENT = 'This document has been approved internally and must be unlocked to make further edits. The approvers will be notified that the document has been unlocked.',
}

export enum SendDocumentButtonMessage {
  SEND_FOR_REVIEW = 'Request Counterparty Review',
  SEND_FOR_SIGNING = 'Ready to Sign',
  PREPARE_FOR_SIGNING = 'Prepare For Signing',
  GRANT_ACCESS = 'Grant Counterparty Access',
  UNLOCK_DOCUMENT = 'Unlock',
}

export type DropdownAction = {
  text: JSX.Element | string;
  id: string;
  onClick: () => void;
  isAvailable: boolean;
  disabled?: boolean;
  order?: number;
  testId?: string;
};

export interface DropdownActions extends Array<DropdownAction> {}

export interface DocumentActionsDropdownProps {
  viewAction: () => void;
  permissions?: DocumentPermissions;
  documentData: Document | DocumentInterface;
  dealId: string;
  isDealShared: boolean;
  isDocumentCreator: boolean;
  teamId: string;
  isDocPlaceholderComplete?: boolean;
}

export type GlobalModalActionsEnum = ModalActionsEnum | CreateDocumentModalsEnum | DealModalActionsEnum;

export enum ResendDealInviteMessage {
  SUCCESS = 'Invite has been resent to the counterparty',
}

export enum UnlockDocumentMessage {
  SUCCESS = 'Document has been unlocked for editing',
}
