import React, { ChangeEvent, useCallback, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import RequestReviewViaEmailForm from './RequestReviewViaEmailForm';
import { TitleFormattedMessage } from '../../../../../../titleFormattedMessage';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIsSendViaEmailModalOpen,
} from '../../../../../../../store/modals/modals.selectors';

const ReviewViaEmailModal = ({
  isOpen,
  onClose,
  teamId,
  dealId,
}: {
  isOpen: boolean;
  onClose: () => void;
  teamId: string;
  dealId: string;
}) => {
  const { formatMessage } = useIntl();

  const isReviewViaEmailModalOpen = useSelector(selectIsSendViaEmailModalOpen);

  const [showModal, setShowModal] = useState(isReviewViaEmailModalOpen);

  // Use useEffect to update the local state whenever the store value changes
  // useEffect(() => {
  //   console.log('ReviewViaEmailModal useEffect isReviewViaEmailModalOpen', isReviewViaEmailModalOpen);
  //   setShowModal(isReviewViaEmailModalOpen);
  // }, [isReviewViaEmailModalOpen]);

  return (
    <Modal show={isOpen} onHide={onClose} size="lg">
      <Modal.Header closeButton closeLabel={formatMessage({ id: 'cta.close', defaultMessage: 'Close' })}>
        <Modal.Title>
          <header>
            <FormattedMessage id="title.add-team-member-to-deal" defaultMessage="Email PDF for review" />
          </header>
        </Modal.Title>
      </Modal.Header>
      <RequestReviewViaEmailForm
        bodyContainer={Modal.Body}
        actionsContainer={Modal.Footer}
        renderSecondaryAction={() => (
          <Button variant="link" className="text-secondary" onClick={onClose}>
            <TitleFormattedMessage id="cta.cancel" defaultMessage="Cancel" />
          </Button>
        )}
        onSuccess={onClose}
        teamId={teamId}
        dealId={dealId}
      />
    </Modal>
  );
};

export default ReviewViaEmailModal;
